import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Icon from '@mdi/react';
import { mdiTimer } from '@mdi/js';

import './budget-countdown-strip-header.m.css';

const BudgetCountdownStripHeader = ({ features, isPath }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(features?.budget?.budget_date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 10000);
  });

  return features?.budget?.header_count_strip &&
    isPath &&
    (timeLeft?.days || timeLeft?.hours || timeLeft?.minutes) ? (
    <div styleName="budget-countdown-strip">
      <div styleName="heading">
        <div styleName="title">
          <Icon path={mdiTimer} size="16px" color="#ffffff" /> BUDGET 2025 &nbsp;
        </div>
        <div className="desktop-only">
          {timeLeft.days ? (
            <>
              Starts in {timeLeft.days} Days : {timeLeft.hours} Hrs : {timeLeft.minutes} Mins
            </>
          ) : timeLeft.hours ? (
            <>
              Starts in {timeLeft.hours} Hrs : {timeLeft.minutes} Mins
            </>
          ) : (
            <> Starts in {timeLeft.minutes} Mins </>
          )}
        </div>
        <div className="mobile-only">
          {timeLeft.days ? (
            <>
              {timeLeft.days}d : {timeLeft.hours}hrs : {timeLeft.minutes}m
            </>
          ) : timeLeft.hours ? (
            <>
              {timeLeft.hours}hrs : {timeLeft.minutes}m
            </>
          ) : (
            <>
              {timeLeft.minutes}m
            </>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  features: get(state, ['qt', 'config', 'features'], {}),
});

export default connect(mapStateToProps, null)(BudgetCountdownStripHeader);
