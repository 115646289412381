import CryptoJS from 'crypto-js';
import { trackPurchaseFailed } from './datalayer-utils';
import { checkStoryType, getbase64url, makeApiCall } from './utils';
import { USER_SUBSCRIBER, USER_SUBSCRIPTION } from '../../store/actions';
import { get } from 'lodash';

const PAYMENT_TYPE = {
  simpl: 'simpl',
  paytm: 'paytm_auto_debit',
  razorpay: 'razorpay',
  razorpay_recurring: 'razorpay_recurring',
};

export const getUserjwtSignedToken = (userInfo, secret) => {
  let header = {
    alg: 'HS256',
    typ: 'JWT',
  };
  let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  let encodedHeader = getbase64url(stringifiedHeader);
  let data = {
    id: getExternalUserId(userInfo?.userId),
    name: userInfo?.fullName || 'Reader',
    email: userInfo?.email,
    'login-phone-number': userInfo?.mobile,
  };
  let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  let encodedData = getbase64url(stringifiedData);

  let token = encodedHeader + '.' + encodedData;

  let signature = CryptoJS.HmacSHA256(token, secret);
  signature = getbase64url(signature);
  let signedToken = token + '.' + signature;
  return signedToken;
};

export const setTokens = (userInfo, accesstypeJwtSecret, metypeJwtSecret) => {
  const token = getUserjwtSignedToken(userInfo, accesstypeJwtSecret);
  localStorage.setItem('UserAccesstypeJwtToken', token);
  const meToken = getUserjwtSignedToken(userInfo, metypeJwtSecret);
  localStorage.setItem('UserMetypeJwtToken', meToken);
  return token;
};

export const setUser = async (userInfo) => {
  const token = localStorage.getItem('UserAccesstypeJwtToken');
  return (
    window.AccessType &&
    window.AccessType.setUser({
      accesstypeJwt: token,
      name: userInfo?.fullName,
      emailAddress: userInfo?.email,
      mobileNumber: userInfo?.mobile,
    })
      .then((res) => {
        return res;
      })
      .catch((error) => console.error(error))
  );
};

export const unsetUser = (dispatch) => {
  dispatch({ type: USER_SUBSCRIBER, payload: false });
  return window.AccessType && window.AccessType.unsetUser();
};

export const getAssetPlan = (storyId) => {
  return makeApiCall(
    `/subscription/get-asset-plans?storyId=${storyId}&origin=${window.location.origin}`,
  ).then((response) => {
    if (response.status) {
      return response.data?.pricing_plans[0];
    } else {
      return {};
    }
  });
};

export const getAllPlans = (story) => {
  const products = [];
  const macroPlans = [];
  const subscriptionPlansPromise = makeApiCall('/subscription/get-subscription-plans').then(
    (subscriptionPlans) => {
      if (subscriptionPlans.status) {
        subscriptionPlans?.data?.subscription_groups.map((plan) => {
          products.push({
            name: plan.title,
            id: plan.id,
            price: plan.price_cents / 100,
            brand: story['story-template'] || 'text',
            category: 'Macro Plan',
            variant: checkStoryType(story),
            quantity: '1',
          });
          macroPlans.push({
            name: plan.title,
            id: plan.id,
            price: plan.price_cents / 100,
            brand: story['story-template'] || 'text',
            category: 'Macro Plan',
            variant: checkStoryType(story),
            quantity: '1',
          });
        });
      }
    },
  );

  const assetPlanPromise = getAssetPlan(story.id).then((res) => {
    products.push({
      name: res.duration_unit,
      id: res.id,
      price: res.price_cents / 100,
      brand: story['story-template'] || 'text',
      category: 'Micro Plan',
      variant: checkStoryType(story),
      quantity: '1',
    });
  });

  const promises = [subscriptionPlansPromise, assetPlanPromise];
  return Promise.all(promises).then(() => products);
};
export const getSubscriptionPlans = () => {
  return makeApiCall('/subscription/get-subscription-plans').then((sp) => {
    if (sp.status) {
      let plans = sp?.data?.subscription_groups?.filter(
        (plan) =>
          plan.duration_length === 1 && plan.duration_unit === 'months' && plan.recurring === true,
      );
      return plans[0];
    }
  });
};

export const getSubscriptionParams = (plan, userInfo) => {
  return {
    type: 'standard',
    plan: {
      id: plan?.id,
      price_cents: plan?.price_cents,
      price_currency: plan?.price_currency,
      title: plan?.title,
    },
    payment: {
      payment_type:
        plan?.recurring === true ? PAYMENT_TYPE['razorpay_recurring'] : PAYMENT_TYPE['razorpay'],
      amount_cents: plan?.price_cents,
      amount_currency: plan?.price_currency,
    },
    metadata: {
      mobile_number: userInfo?.mobile,
    },
    additional_data: {
      phone_number: userInfo?.mobile,
      email: userInfo?.email,
    },
  };
};

export const getPaymentOptionsAndPay = async (
  subscriptionParams,
  paymentType = 'razorpay',
  onPaymentSuccess,
  onPaymentFailure,
  pageType,
) => {
  try {
    return await window.AccessType.getPaymentOptions().then((paymentOptions) => {
      return paymentOptions[PAYMENT_TYPE[paymentType]]
        .proceed(subscriptionParams)
        .then(async (updatedPaymentOption) => {
          const subscription = updatedPaymentOption?.subscription;

          localStorage.removeItem('USER_SELECTED_PLAN');
          await onPaymentSuccess(subscription);
          await paymentSuccess(updatedPaymentOption);
          return updatedPaymentOption;

          //save updatedPaymentOption in which action will be updated to the next step.
        })
        .catch((e) => {
          onPaymentFailure();
          return paymentError(e);
        });
    });
  } catch (error) {
    return paymentError(error);
  }
};

export const paymentSuccess = (successResponse) => {
  const subscription = get(successResponse, ['subscription']);
  const invoice = get(subscription, ['invoices', '0'], '');
  const eventData = {
    subscription_id: get(subscription, ['id']),
    sequenced_invoice_number: get(invoice, ['sequenced_invoice_number'], ''),
    created_at: get(invoice, ['created_at'], ''),
    payment_type: get(subscription, ['payment_type']),
    article_title: get(subscription, ['dynamic_assets', '0', 'title']),
    article_price: get(subscription, ['plan_amount_cents'], 0) / 100,
    base_price: get(invoice, ['base_price'], ''),
    discount_amount: get(invoice, ['discount_details', 'discount_amount'], ''),
    discount_percentage: get(invoice, ['discount_details', 'discount_percentage'], ''),
    total_price: get(invoice, ['amount_after_discount_before_tax'], ''),
    CGST: get(invoice, ['invoice_taxes', 'CGST', 'amount'], ''),
    SGST: get(invoice, ['invoice_taxes', 'SGST', 'amount'], ''),
    payment_amount: get(subscription, ['payment_amount']),
  };
  return successResponse;
};

export const paymentError = (error) => {
  trackPurchaseFailed();
  return error;
};

export const checkForSubscription = (dispatch, atToken) => {
  return makeApiCall(
    `/subscription/check-for-subscription?jwt=${atToken}&origin=${window.location.origin}`,
    'GET',
    {},
    {},
    { cache: 'no-store' },
  )
    .then((response) => {
      if (response.status) {
        const purchasedSubscription = response.data.subscriptions.find(
          (subscription) =>
            subscription.subscription_type === 'standard' && subscription.status === 'active',
        );
        const otherSubscription = response.data.subscriptions
          .map(
            (subscription) =>
              subscription.subscription_type === 'standard' &&
              subscription.status !== 'active' &&
              subscription,
          )
          .filter(Boolean);
        setSubscriptionDetails(purchasedSubscription, otherSubscription, response.data, dispatch);
        return purchasedSubscription;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.log('error====>>', err);
    });
};

export const setSubscriptionDetails = async (
  purchasedSubscription,
  otherSubscription,
  response,
  dispatch,
) => {
  const payPerArticle = response.subscriptions.find(
    (subscription) => subscription.subscription_type === 'dynamic_asset',
  );
  const additionalData = {
    payPerArticleStatus: payPerArticle ? 'purchased' : 'not purchased',
    access: payPerArticle?.dynamic_assets,
  };

  if (purchasedSubscription) {
    await dispatch({ type: USER_SUBSCRIBER, payload: true });
    await dispatch({
      type: USER_SUBSCRIPTION,
      payload: {
        active: purchasedSubscription,
        otherSubscription: otherSubscription,
        ...additionalData,
      },
    });
    const date = new Date();
    date.setTime(date.getTime() + 180 * 24 * 60 * 60 * 1000);
    return purchasedSubscription;
  } else {
    dispatch({ type: USER_SUBSCRIPTION, payload: null });
    dispatch({ type: USER_SUBSCRIBER, payload: false });
    return purchasedSubscription;
  }
};

export const downloadInvoice = (subscriptionId, invoiceId, accesstype) => {
  try {
    const atToken = localStorage.getItem('UserAccesstypeJwtToken');
    return fetch(
      `${accesstype.host}/api/access/v1/members/me/subscriptions/${subscriptionId}/invoices/${invoiceId}/download?key=${accesstype.accountId}&accesstype_jwt=${atToken}`,
      {
        headers: {
          accept: '*/*',
          Origin: window.location.origin,
        },
      },
    )
      .then((response) => {
        if (response) {
          return response.blob();
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (err) {
    return err;
  }
};

export const getExternalUserId = (uid) => {
  return `np-${uid}`;
};

export const getSSORedirectUrl = async (url) => {
  let pageUrl = url?.href;
  let params = new URL(url)?.searchParams;
  const loginMethod = params?.get('loginMethod');
  const loginAccessType = params?.get('accessType');
  if (loginMethod) {
    localStorage.setItem('loginMethod', loginMethod);
    localStorage.setItem('loginAccessType', loginAccessType);
  }
  let source = params?.get('source');
  let AMPredirectUrl = params?.get('redirect-url');
  if (source === 'amp-story' && AMPredirectUrl) {
    if (AMPredirectUrl.indexOf('?') > -1) {
      AMPredirectUrl = AMPredirectUrl.substring(0, AMPredirectUrl.indexOf('?'));
    }
    pageUrl = AMPredirectUrl;
  } else {
    if (pageUrl.indexOf('?') > -1) {
      pageUrl = pageUrl.substring(0, pageUrl.indexOf('?'));
    }
  }
  return pageUrl;
};

export const getSSOLoginUrl = async (client_id, client_secret, redirectUrl, SSO_LOGIN_URL) => {
  let ssoUrl = await (SSO_LOGIN_URL || 'https://sso-dev.ndtvprofit.com/login');
  let pageUrl = await redirectUrl;

  return `${ssoUrl}?client_id=${client_id}&client_secret=${client_secret}&redirectUrl=${pageUrl}`;
};

export const getSSOLogoutUrl = async (client_id, client_secret, redirectUrl, SSO_LOGOUT_URL) => {
  let ssoUrl = await (SSO_LOGOUT_URL || 'https://sso-dev.ndtvprofit.com/logout');
  let pageUrl = await redirectUrl;
  localStorage.removeItem('UserAccesstypeJwtToken');
  localStorage.removeItem('UserMetypeJwtToken');
  return `${ssoUrl}?client_id=${client_id}&client_secret=${client_secret}&redirectUrl=${pageUrl}`;
};
