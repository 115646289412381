import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import BudgetCalculator from '../../atoms/budget-calculator';

const BudgetCalculatorFullWidget = (props) => {
  const [client, setClient] = useState('');
  const [darkMode, setDarkMode] = useState(false);
  const [lightMode, setLightMode] = useState(false);
  const queryClient = new QueryClient;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const dm = urlParams.get('dm');
    const disabledm = urlParams.get('disabledm');
    const client = urlParams.get('client');

    if (dm === "1") {
      setDarkMode(true);
      setLightMode(false);
    } else if (disabledm === "1") {
      setDarkMode(false);
      setLightMode(true);
    } else {
      setDarkMode(false);
      setLightMode(false);
    }

    if (!!client) {
      setClient(client);
    }

    if (disabledm === "1") {
      const body = document.getElementsByTagName('body')[0];
      body.style.backgroundColor = "#FFFFFF";
    } else if (dm === "1" || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      const body = document.getElementsByTagName('body')[0];
      body.style.backgroundColor = "#2B2B2B";
    }
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <BudgetCalculator widget={true} client={client} darkMode={darkMode} lightMode={lightMode} {...props}/>
    </QueryClientProvider>
  );
};

export { BudgetCalculatorFullWidget };
