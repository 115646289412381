/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */

import { get } from 'lodash';
import {
  formatPublishedDate,
  getDDMMYYYY,
  getExtendedUserInfo,
  getStorySectionByScroll,
  getUserDetails,
  getWhatsappOptIn,
} from './utils';
import { isMobileOnly } from 'react-device-detect';

export function impression(impressions) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'impression',
      ecommerce: {
        currencyCode: 'INR',
        impressions,
      },
    });
}
export function sendProductView(products) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'productView',
      ecommerce: {
        detail: {
          actionField: { list: 'Subscription' },
          products: [{ ...products, source: window.location.pathname.substring(1) }],
        },
      },
    });
}
export function trackClick(products) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: 'Subscription' },
          products: [{ ...products, source: window.location.pathname.substring(1) }],
        },
      },
    });
}

export function sendProductDetail(products) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'productDetail',
      ecommerce: {
        detail: {
          actionField: {},
          products: [
            products.map((item) => ({ ...item, source: window.location.pathname.substring(1) })),
          ],
        },
      },
    });
}

export function trackAddToCart(product) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'INR',
        add: {
          actionField: { step: 1, option: 'Visa', list: 'Subscription' },
          products: [
            product.map((item) => ({ ...item, source: window.location.pathname.substring(1) })),
          ],
        },
      },
    });
}

export function trackCheckout(product) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: 'Visa', list: 'Subscription' },
          products: [
            product.map((item) => ({ ...item, source: window.location.pathname.substring(1) })),
          ],
        },
      },
    });
}

export function trackPurchase(actionField, product) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currencyCode: 'INR',
        purchase: {
          actionField,
          products: [
            product.map((item) => ({ ...item, source: window.location.pathname.substring(1) })),
          ],
        },
      },
    });
}

export function trackPurchaseSuccess(pageType) {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'subscription_success',
    page_template: formattedPageType,
    user_id: 'NA',
    user_status: 'subscriber',
    login_type: 'logged-in',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    subscription_status: 'active',
    plan_duration: 'NA',
    plan_start_date: 'NA',
    plan_end_date: 'NA',
    plan_final_amount: 'NA',
    SiteName: 'https://www.ndtvprofit.com'
  };

  if (typeof window !== 'undefined' && window.dataLayer) {
    const userProperty = [...window.dataLayer].reverse().find((item) => item.event === 'user_property');
    if (userProperty) {
      baseData.user_id = userProperty.user_id || 'NA';
      baseData.plan_duration = userProperty.plan_duration || 'NA';
      baseData.plan_start_date = userProperty.plan_start_date || 'NA';
      baseData.plan_end_date = userProperty.plan_end_date || 'NA';
      baseData.plan_final_amount = userProperty.plan_final_amount || 'NA';
    } else {
      console.warn('userProperty event not found in dataLayer');
    }
    window.dataLayer.push(baseData);
  } else {
    console.error('Error: window.dataLayer is not available');
  }

}

export function trackPurchaseFailed() {
  const baseData = {
    event: 'payment_failed',
    subscription_price: 'NA',
    subscription_plan_duration: 'NA',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    const subscriptionInitiate = [...window.dataLayer].reverse().find((item) => item.event === 'subscription_initiate');
    if (subscriptionInitiate) {
      baseData.subscription_price = subscriptionInitiate.subscription_price || 'NA';
      baseData.subscription_plan_duration = subscriptionInitiate.subscription_plan_duration || 'NA';
    } else {
      console.warn('Plan selection event not found in dataLayer');
    }
    window.dataLayer.push(baseData);
  } else {
    console.error('Error: window.dataLayer is not available');
  }
}

export function trackUserInteraction(datalayerFields) {
  window && window.dataLayer && window.dataLayer.push(datalayerFields);
}


export async function paywallWidgetImpression(pageType) {
  setTimeout(() => {
    if (!window || !window.dataLayer) return;

    const queryParams = new URLSearchParams(window.location.search);
    const utmSource = queryParams.get('utm_source');
    const filteredParams = utmSource ? `?utm_source=${utmSource}` : '';
    const referrer = document.referrer;
    const currentDomain = window.location.hostname;
    const referrerValue = referrer && referrer.includes(currentDomain) ? '' : referrer;
    const referralTrafficVariables =
      filteredParams ||
      referrerValue ||
      localStorage.getItem('referral_traffic_variables') ||
      localStorage.getItem('referral_traffic_variables_oneachload') ||
      'direct';

    const pagePropertyEvent = window.dataLayer.find((item) => item.event === 'page_property');
    if (pagePropertyEvent) {
      const cmsid = pagePropertyEvent.cmsid;
      const paywallSource = pagePropertyEvent.page_access || 'widget impression';
      localStorage.setItem('cms_id', cmsid);
      localStorage.setItem('paywall_source', paywallSource);
    } else {
      console.log('page_property event not found in dataLayer');
    }

    const cmsid = localStorage.getItem('cms_id');
    const paywallSource = localStorage.getItem('paywall_source');

    let widgetType;
    if (pageType === 'section-page') {
      widgetType = window.scrollY < 1000 ? 'exclusive_section' : 'footer';
    } else if (pageType === 'story-page') {
      widgetType = window.scrollY > 1000 ? 'footer' : `article blocker widget | ${cmsid}`;
    } else if (pageType === 'home-page') {
      widgetType = window.scrollY < 3500 ? 'exclusive_hmpg' : 'footer';
    } else {
      widgetType = 'footer';
    }

    let paywallSourceLabel;
    if (widgetType === `article blocker widget | ${cmsid}`) {
      localStorage.setItem('article_blocker_widgetType', widgetType);
      switch (paywallSource) {
        case 'paid':
          paywallSourceLabel = 'hard paywall';
          break;
        case 'metered':
          paywallSourceLabel = 'metered paywall';
          break;
        default:
          paywallSourceLabel = 'widget impression';
      }
    } else {
      paywallSourceLabel = 'widget impression';
    }

    const data = {
      event: 'paywall_widget_impression',
      paywall_source: paywallSourceLabel,
      referral_traffic_variables: referralTrafficVariables,
      widget_type: widgetType,
    };

    localStorage.setItem('paywall_source', data.paywall_source);
    localStorage.setItem('referral_traffic_variables', data.referral_traffic_variables);
    localStorage.setItem('widget_type', data.widget_type);
  }, 2000);
}

export async function paywallCtaClick() {
  const urlParams = new URLSearchParams(window.location.search);
  const srcParam = urlParams.get('src');

  if (!srcParam || !window || !window.dataLayer) return;

  const defaultValues = {
    widgetType: 'unknown widget Type',
    ctaPosition: 'direct',
  };

  const mappings = {
    srcToCtaPosition: {
      subsc_excl: 'exclusives section',
      subs_hdr: 'header',
      subsc_ftr: 'footer',
      exclvs_subsc: 'homepageWidget',
      frpaywl: 'article blocker widget',
      hrdpaywl: 'article blocker widget',
    },
    srcToPaywallSource: {
      frpaywl: 'metered paywall',
      hrdpaywl: 'hard paywall',
    },
    ctaPositionToCtaText: {
      'exclusives section': 'Subscribe Now',
      'article blocker widget': 'Subscribe Now',
      header: 'Subscribe',
      footer: 'Subscribe Now',
      homepageWidget: 'Subscribe @ ₹48/week',
    },
  };

  const ctaPosition = mappings.srcToCtaPosition[srcParam] || defaultValues.ctaPosition;
  const ctaText = mappings.ctaPositionToCtaText[ctaPosition] || 'direct';
  const paywallSource = mappings.srcToPaywallSource[srcParam] || 'widget impression';

  const otherWidgetTypes =
    srcParam === 'subsc_excl'
      ? 'exclusive_section'
      : srcParam === 'subs_hdr'
        ? 'header'
        : srcParam === 'subsc_ftr'
          ? 'footer'
          : srcParam === 'exclvs_subsc'
            ? 'exclusive_hmpg'
            : defaultValues.widgetType;

  const widgetType =
    paywallSource === 'widget impression'
      ? otherWidgetTypes
      : localStorage.getItem('article_blocker_widgetType') || 'article blocker widget';

  const storyUrl = window.location.href;
  const queryParams = new URLSearchParams(storyUrl.split('?')[1]);

  const filteredParams = queryParams.has('utm_source') ? '?' + queryParams.toString() : '';

  const referrer = document.referrer;
  const currentDomain = window.location.hostname;

  const referrerValue =
    referrer && referrer.includes(currentDomain) ? 'direct' : referrer || 'direct';

  const referralTrafficVariables =
    filteredParams ||
    referrerValue ||
    localStorage.getItem('referral_traffic_variables') ||
    localStorage.getItem('referral_traffic_variables_oneachload') ||
    'direct';
  const data = {
    event: 'paywall_cta_click',
    referral_traffic_variables: referralTrafficVariables,
    cta_position: ctaPosition,
    cta_text: ctaText,
    paywall_source: paywallSource,
    widget_type: widgetType,
  };

  localStorage.setItem('referral_traffic_variables', referralTrafficVariables);
  localStorage.setItem('paywall_source', paywallSource);
  localStorage.setItem('cta_text', ctaText);
  localStorage.setItem('cta_position', ctaPosition);
  localStorage.setItem('widget_type', widgetType);

}

export function paywallPlanSelectContinue(term) {
  if (!window || !window.dataLayer) return;
  const referralTrafficVariables = localStorage.getItem('referral_traffic_variables') || 'direct';
  const ctaText = localStorage.getItem('cta_text') || 'direct';
  const ctaPosition = localStorage.getItem('cta_position') || 'direct';
  const paywallSource = localStorage.getItem('paywall_source') || 'direct';
  const widgetType = localStorage.getItem('widget_type') || 'direct';
  const selectedPlanName = term?.name || 'NA';
  const selectedPlanId = term?.termId || 'NA';
  const finalAmount = term?.totalAmount || localStorage.getItem('final_amount') || 'NA';
  const formattedFinalAmount = finalAmount.replace(/INR\s*/i, '').replace(',', '');
  const finalAmountWithoutDecimals = parseInt(formattedFinalAmount.replace(/\.\d+$/, ''), 10);
  const currency = term?.chargeCurrency || 'INR';

  const data = {
    event: 'paywall_plan_select_continue',
    selected_plan_name: selectedPlanName,
    selected_plan_id: selectedPlanId,
    final_amount: finalAmountWithoutDecimals,
    currency: currency,
    referral_traffic_variables: referralTrafficVariables,
    cta_text: ctaText,
    cta_position: ctaPosition,
    paywall_source: paywallSource,
    widget_type: widgetType,
  };

  localStorage.setItem('referral_traffic_variables', data.referral_traffic_variables);
  localStorage.setItem('paywall_source', data.paywall_source);
  localStorage.setItem('widget_type', data.widget_type);
  localStorage.setItem('cta_position', data.cta_position);
  localStorage.setItem('cta_text', data.cta_text);
  localStorage.setItem('selected_plan_name', data.selected_plan_name);
  localStorage.setItem('selected_plan_id', data.selected_plan_id);
  localStorage.setItem('final_amount', data.final_amount);
  localStorage.setItem('currency', data.currency);
  window.dataLayer.push(data);
}

export function paywallCheckoutPopupCouponApplied() {
  if (window && window.dataLayer) {
    const referralTrafficVariables = localStorage.getItem('referral_traffic_variables');
    const ctaText = localStorage.getItem('cta_text');
    const ctaPosition = localStorage.getItem('cta_position');
    const paywallSource = localStorage.getItem('paywall_source') || 'direct';
    const widgetType = localStorage.getItem('widget_type') || 'direct';
    const selectedPlanName = localStorage.getItem('selected_plan_name') || '';
    const selectedPlanId = localStorage.getItem('selected_plan_id') || '';
    const final_amount = localStorage.getItem('final_amount') || '';
    const currency = localStorage.getItem('currency') || 'INR';
    const data = {
      event: 'paywall_checkout_popup_coupon_applied',
      selected_plan_name: selectedPlanName,
      selected_plan_id: selectedPlanId,
      final_amount: final_amount,
      currency: currency,
      referral_traffic_variables: referralTrafficVariables,
      cta_text: ctaText,
      cta_position: ctaPosition,
      paywall_source: paywallSource,
      widget_type: widgetType,
      applied_coupon_code: 'NA',
    };

    localStorage.setItem('applied_coupon_code', data.applied_coupon_code);

    window.dataLayer.push(data);
  }
}

export function paywallCheckoutPaymentMethodContinue() {
  if (window && window.dataLayer) {
    const referralTrafficVariables = localStorage.getItem('referral_traffic_variables');
    const ctaText = localStorage.getItem('cta_text');
    const ctaPosition = localStorage.getItem('cta_position');
    const paywallSource = localStorage.getItem('paywall_source') || 'direct';
    const widgetType = localStorage.getItem('widget_type') || 'direct';
    const selectedPlanName = localStorage.getItem('selected_plan_name') || 'NA';
    const selectedPlanId = localStorage.getItem('selected_plan_id') || 'NA';
    const final_amount = localStorage.getItem('final_amount') || 'NA';
    const currency = localStorage.getItem('currency') || 'INR';
    const appliedCouponCode = localStorage.getItem('applied_coupon_code') || 'NA';
    const data = {
      event: 'paywall_checkout_payment_method_continue',
      selected_plan_name: selectedPlanName,
      selected_plan_id: selectedPlanId,
      final_amount: final_amount,
      currency: currency,
      referral_traffic_variables: referralTrafficVariables,
      cta_text: ctaText,
      cta_position: ctaPosition,
      paywall_source: paywallSource,
      widget_type: widgetType,
      applied_coupon_code: appliedCouponCode,
      payment_method_name: 'UPI',
    };

    localStorage.setItem('payment_method_name', data.payment_method_name);

    window.dataLayer.push(data);
  }
}

export function paywallCheckoutPaymentCompleted() {
  if (window && window.dataLayer) {
    const referralTrafficVariables = localStorage.getItem('referral_traffic_variables');
    const ctaText = localStorage.getItem('cta_text');
    const ctaPosition = localStorage.getItem('cta_position');
    const paywallSource = localStorage.getItem('paywall_source') || 'direct';
    const widgetType = localStorage.getItem('widget_type') || 'direct';
    const selectedPlanName = localStorage.getItem('selected_plan_name') || 'NA';
    const selectedPlanId = localStorage.getItem('selected_plan_id') || 'NA';
    const final_amount = localStorage.getItem('final_amount') || 'NA';
    const currency = localStorage.getItem('currency') || 'INR';
    const appliedCouponCode = localStorage.getItem('applied_coupon_code') || 'NA';
    const paymentMethodName = localStorage.getItem('payment_method_name') || 'NA';
    const data = {
      event: 'paywall_checkout_popup',
      selected_plan_name: selectedPlanName,
      selected_plan_id: selectedPlanId,
      final_amount: final_amount,
      currency: currency,
      referral_traffic_variables: referralTrafficVariables,
      cta_text: ctaText,
      cta_position: ctaPosition,
      paywall_source: paywallSource,
      widget_type: widgetType,
      applied_coupon_code: appliedCouponCode,
      payment_method_name: paymentMethodName,
    };

    window.dataLayer.push(data);
  }
}

export function paywallCheckoutPaymentFailed() {
  if (window && window.dataLayer) {
    const referralTrafficVariables = localStorage.getItem('referral_traffic_variables');
    const ctaText = localStorage.getItem('cta_text');
    const ctaPosition = localStorage.getItem('cta_position');
    const paywallSource = localStorage.getItem('paywall_source') || 'direct';
    const widgetType = localStorage.getItem('widget_type') || 'direct';
    const selectedPlanName = localStorage.getItem('selected_plan_name') || 'NA';
    const selectedPlanId = localStorage.getItem('selected_plan_id') || 'NA';
    const final_amount = localStorage.getItem('final_amount') || 'NA';
    const currency = localStorage.getItem('currency') || 'INR';
    const appliedCouponCode = localStorage.getItem('applied_coupon_code') || 'NA';
    const paymentMethodName = localStorage.getItem('payment_method_name') || 'NA';
    const data = {
      event: 'paywall_checkout_popup',
      selected_plan_name: selectedPlanName,
      selected_plan_id: selectedPlanId,
      final_amount: final_amount,
      currency: currency,
      referral_traffic_variables: referralTrafficVariables,
      cta_text: ctaText,
      cta_position: ctaPosition,
      paywall_source: paywallSource,
      widget_type: widgetType,
      applied_coupon_code: appliedCouponCode,
      payment_method_name: paymentMethodName,
      failure_reason: 'gateway closed',
    };

    localStorage.setItem('failure_reason', data.failure_reason);

    window.dataLayer.push(data);
  }
}

export function paywallSubscriptionDone() {
  if (window && window.dataLayer) {
    const referralTrafficVariables = localStorage.getItem('referral_traffic_variables');
    const ctaText = localStorage.getItem('cta_text');
    const ctaPosition = localStorage.getItem('cta_position');
    const paywallSource = localStorage.getItem('paywall_source') || 'direct';
    const widgetType = localStorage.getItem('widget_type') || 'direct';
    const selectedPlanName = localStorage.getItem('selected_plan_name') || 'NA';
    const selectedPlanId = localStorage.getItem('selected_plan_id') || 'NA';
    const final_amount = localStorage.getItem('final_amount') || 'NA';
    const currency = localStorage.getItem('currency') || 'INR';
    const appliedCouponCode = localStorage.getItem('applied_coupon_code') || 'NA';
    const paymentMethodName = localStorage.getItem('payment_method_name') || 'NA';
    const data = {
      event: 'paywall_checkout_popup',
      selected_plan_name: selectedPlanName,
      selected_plan_id: selectedPlanId,
      final_amount: final_amount,
      currency: currency,
      referral_traffic_variables: referralTrafficVariables,
      cta_text: ctaText,
      cta_position: ctaPosition,
      paywall_source: paywallSource,
      widget_type: widgetType,
      applied_coupon_code: appliedCouponCode,
      payment_method_name: paymentMethodName,
    };

    window.dataLayer.push(data);
  }
}

export function loginWidgetImp() {
  if (!window || !window.dataLayer) return;
  const storyUrl = window.location.href;
  const queryParams = new URLSearchParams(storyUrl.split('?')[1]);
  const utmSource = queryParams.get('utm_source');
  const filteredParams = utmSource ? `?utm_source=${utmSource}` : '';

  const referrer = document.referrer;
  const currentDomain = window.location.hostname;
  const referrerValue =
    referrer && referrer.includes(currentDomain) ? 'direct' : referrer || 'direct';

  const referralTrafficVariables =
    filteredParams ||
    referrerValue ||
    localStorage.getItem('referral_traffic_variables') ||
    localStorage.getItem('referral_traffic_variables_oneachload') ||
    'direct';

  const paywallSource = localStorage.getItem('paywall_source') || 'direct';
  const widgetType = localStorage.getItem('widget_type') || 'direct';
  const ctaPosition = localStorage.getItem('cta_position') || 'direct';
  const ctaText = localStorage.getItem('cta_text') || 'direct';
  const loginPopupCase = localStorage.getItem('login_popup_case');

  const data = {
    event: 'login_widget_impression',
    login_popup_case: loginPopupCase,
    referral_traffic_variables: referralTrafficVariables,
    paywall_source: paywallSource,
    widget_type: widgetType,
    cta_text: ctaText,
    cta_position: ctaPosition,
  };

  const existingEventIndex = window.dataLayer.findIndex(
    (item) => item.event === 'login_widget_impression'
  );

  if (existingEventIndex !== -1) {
    window.dataLayer[existingEventIndex] = data;
  } else {
    window.dataLayer.push(data);
  }

  localStorage.setItem('referral_traffic_variables', data.referral_traffic_variables);
  localStorage.setItem('paywall_source', data.paywall_source);
  localStorage.setItem('widget_type', data.widget_type);
  localStorage.setItem('cta_position', data.cta_position);
  localStorage.setItem('cta_text', data.cta_text);
  localStorage.setItem('login_popup_case', data.login_popup_case);
}

export function loginCompleted() {
  if (!window || !window.dataLayer) return;
  const queryString = localStorage.getItem('referral_traffic_variables');
  const paywallSource = localStorage.getItem('paywall_source') || 'direct';
  const widgetType = localStorage.getItem('widget_type') || 'direct';
  const ctaPosition = localStorage.getItem('cta_position');
  const ctaText = localStorage.getItem('cta_text');
  const loginPopupCase = localStorage.getItem('login_popup_case');
  const loginSource = localStorage.getItem('login_source');

  const data = {
    event: 'login_completed',
    login_source: loginSource,
    login_type: 'signin',
    login_popup_case: loginPopupCase,
    referral_traffic_variables: queryString,
    paywall_source: paywallSource,
    widget_type: widgetType,
    cta_text: ctaText,
    cta_position: ctaPosition,
  };

  localStorage.setItem('login_type', data.login_type);
  window.dataLayer.push(data);
}

export function logOut() {
  if (window && window.dataLayer) {
    const data = {
      event: 'logOut',
    };
    window.dataLayer.push(data);
  }
}
// login Funnel ends above


export async function pianoLazyEvents(action) {
  try {
    switch (action.event) {
      case 'LOGIN_SUCCESS':
        const {
          payload: { aid, aesKey, loginType },
        } = action;
        const dataLayerFields = await getUserDetails(aid, aesKey);
        const { userDetails = {} } = dataLayerFields;
        const signInEvent = {
          // remove this after ga4 integration
          event: 'loginInfo',
          userName: `${userDetails.firstName}`,
          userEmail: userDetails.email || 'NA',
          userMobileNumber: userDetails.phone || 'NA',
          uid: userDetails.subscriberId || 'NA',
          loginStatus: 'logged in',
        };
        const signUpEvent = {
          event: 'signUpInfo',
          userName: `${userDetails.firstName} ${userDetails.lastName}` || 'NA',
          userEmail: userDetails.email || 'NA',
          userMobileNumber: userDetails.phone || 'NA',
          uid: userDetails.subscriberId || 'NA',
        };
        const userEvent = loginType === 'sign-up' ? signUpEvent : signInEvent;
        window.tp.pianoId.loadExtendedUser({
          extendedUserLoaded: (externalData) => {
            const contact = getExtendedUserInfo(externalData, 'phone_number');
            getWhatsappOptIn(contact);
          },
          formName: 'RegistrationFields',
        });
        window && window.dataLayer && window.dataLayer.push(userEvent);
    }
  } catch (err) {
    return err;
  }
}

export const sectionNameDataLayer = (name, userData, pageType) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';

  const baseData = {
    event: 'section_header_interaction',
    cta_text: name,
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'
  }
  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData)
  }
};

export const loadMoreInteraction = (name, userData, pageType, sectionName) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'load_more_interaction',
    cta_text: name || 'NA',
    section_name: sectionName || 'NA',
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'

  }
  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData)
  }
};
export const contentClick = (userData, story, pageType) => {
  const {
    headline = 'NA',
    alternative,
    id = 'NA',
    'author-name': authorName = 'NA',
    'last-published-at': lastPublishedAt,
    'updated-at': updatedAt,
  } = story;
  const finalUpdatedAt = lastPublishedAt || updatedAt || 'NA';
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const publishedAt = get(story, 'first-published-at', get(story, 'published-at', 'NA'));
  const publishedDateTime = publishedAt !== 'NA' ? formatPublishedDate(publishedAt) : 'NA, NA';
  const [time, date] = publishedDateTime.split(',');
  const publishedTime = time.trim() || 'NA';
  const publishedDate = getDDMMYYYY(publishedAt) || 'NA';
  const updatedAtDate = getDDMMYYYY(finalUpdatedAt) || 'NA';
  const alternativeHeadline = alternative && alternative.headline ? alternative.headline : 'NA';
  const storyTemplate = get(story, ['story-template'], '');
  const contentType = (storyTemplate === 'video' || storyTemplate === 'ndtv-video') ? 'video' : storyTemplate === 'web-story' ? 'web-story' : 'article';
  const slug = get(story, ['slug'], 'NA');
  const storyCategory = slug !== 'NA' ? slug.split('/')[0] : 'NA';
  const source =
    get(story, ['metadata', 'story-attributes', 'syndicatedfrom', '0'], 'NDTV Profit');
  const authors = authorName;
  const pageTitle = headline || alternativeHeadline || 'NA';
  const authorNames = authors || 'NA';
  const baseData = {
    event: 'content_click',
    page_title: pageTitle || 'NA',
    page_template: formattedPageType || 'NA',
    authors: authorNames,
    agency: source,
    category: storyCategory || 'NA',
    publish_date: publishedDate || 'NA',
    publish_time: publishedTime || 'NA',
    last_update_date: updatedAtDate || 'NA',
    tvc_article_id: id || 'NA',
    sub_category: 'NA',
    section_name: getStorySectionByScroll(),
    ContentType: contentType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'
  };

  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }

  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData);
  }
};

export const followIconsDl = (name, pageType, userData, sectionName) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'follow_us_click',
    cta_text: name ? name : "NA",
    section_name: sectionName || getStorySectionByScroll(),
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'
  }

  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData)
  }
};
export const shareIconDL = (name, story, userData, sectionName) => {
  const {
    headline = 'NA',
    id = 'NA',
    'author-name': authorName = 'NA',
    'updated-at': updatedAt,
  } = story;
  const publishedAt = get(story, 'first-published-at', get(story, 'published-at', 'NA'));
  const publishedDateTime = publishedAt !== 'NA' ? formatPublishedDate(publishedAt) : 'NA, NA';
  const [time, date] = publishedDateTime?.split(',');
  const publishedTime = time?.trim() || 'NA';
  const publishedDate = getDDMMYYYY(publishedAt) || 'NA';
  const updatedAtDateTime = getDDMMYYYY(updatedAt) || 'NA';
  const slug = get(story, ['slug'], 'NA');
  const category = slug !== 'NA' ? slug.split('/')[0] : 'NA';
  const pageType = 'story';
  const source =
    get(story, ['metadata', 'story-attributes', 'syndicatedfrom', '0'], 'NDTV Profit');

  const authors = authorName;
  const storyTemplate = get(story, ['story-template'], 'article');
  const contentType = (storyTemplate === 'video' || storyTemplate === 'ndtv-video') ? 'video' : storyTemplate === 'web-story' ? 'web-story' : 'article';

  const baseData = {
    event: 'share_icon_interaction',
    page_title: headline || 'NA',
    cta_text: name || 'NA',
    page_template: pageType || 'NA',
    authors: authors || 'NA',
    agency: source || 'NA',
    category: category || 'NA',
    publish_date: publishedDate || 'NA',
    publish_time: publishedTime || 'NA',
    last_update_date: updatedAtDateTime || 'NA',
    tvc_article_id: id || 'NA',
    sub_category: 'NA',
    section_name: sectionName || 'NA',
    ContentType: contentType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'
  }

  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }

  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData);
  }
}

export const newsLetterDl = (userData, pageType, section, newsletterType) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'newsletter_signup_interaction',
    cta_text: 'sign up',
    section_name: section,
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    newsletter_name: newsletterType || 'The Talking Point',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'

  }
  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData)
  }
};
export const getInTouchInteraction = (name, userData, pageType) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'get_in_touch_interaction',
    cta_text: name || 'NA',
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'

  }
  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData)
  }
};

export const downloadAppDl = (name, userData, pageType, selected) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'download_app_interaction',
    select_type: selected,
    cta_text: name ? name : 'NA',
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'

  }
  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData)
  }
};
export const watchLiveInteraction = (userData, pageType, sectionName) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'watch_live_interaction',
    section_name: sectionName,
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'
  }
  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData)
  }
};

export const languageSelection = (pageType, userData, selectType) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'language_selection',
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    select_type: selectType,
    language_type: 'hindi',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'
  }

  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }

  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData);
  } else {
    console.error('Error: window.dataLayer is not available');
  }
};

export const subscribeClick = (userData, pageType, sectionName) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'subscribe_interaction',
    section_name: sectionName,
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'
  }
  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData)
  }
};
export const signInClick = (userData, pageType, ctaText, sectionName) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'login_signup_initiate',
    cta_text: ctaText,
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: 'NA',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    section_name: sectionName || 'NA',
    SiteName: 'https://www.ndtvprofit.com'
  }

  if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    localStorage.setItem('login_section_name', sectionName);
    window.dataLayer.push(baseData)
  }
};

export const loginSignupSuccess = (userData, pageType, subscriber) => {
  const LOGIN_SUCCESS_FLAG = 'loginSignupSuccessTriggered'; // Key to track state
  // Check if the event was already triggered
  const isTriggered = localStorage.getItem(LOGIN_SUCCESS_FLAG) === 'true';
  if (isTriggered) {
    console.warn('loginSignupSuccess already triggered. Skipping...');
    return; // Prevent further execution
  }

  const loginOrSignupMethod = localStorage.getItem('loginMethod');
  const accessType = localStorage.getItem('loginAccessType');
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'login_signup_success',
    login_method: loginOrSignupMethod,
    access_type: accessType,
    page_template: formattedPageType,
    section_name: localStorage.getItem('login_section_name') || 'NA',
    email: userData?.userProfile?.email || 'NA',
    full_name: userData?.userProfile?.fullName || 'NA',
    mobile: userData?.userProfile?.mobile || 'NA',
    user_id: userData?.userProfile?.userId || userData?.userId || 'NA',
    user_status: 'NA',
    login_type: 'logged-in',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'
  };

  if (window?.location?.pathname === '/subscription') {
    baseData.user_status = subscriber ? 'subscriber' : 'non-subscriber';
  } else if (userData?.isLoggedIn) {
    baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
  } else {
    baseData.user_status = 'anonymous-user';
  }

  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData);

    // Mark as triggered after pushing to dataLayer
    localStorage.setItem(LOGIN_SUCCESS_FLAG, 'true');
  }
};

export const accountInteraction = (userData, pageType, selectType, ctaText) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'account_interaction',
    cta_text: ctaText,
    select_type: selectType,
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: userData?.isLoggedIn ? (userData?.isSubscriber ? 'subscriber' : 'non-subscriber') : 'anonymous-user',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    SiteName: 'https://www.ndtvprofit.com'
  };
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData);
  } else {
    console.error('Error: window.dataLayer is not available');
  }
};

export const planSelection = (userData, pageType, ctaText, price) => {
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  const baseData = {
    event: 'plan_selection',
    cta_text: ctaText,
    page_template: formattedPageType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: userData?.isLoggedIn ? (userData?.isSubscriber ? 'subscriber' : 'non-subscriber') : 'anonymous-user',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    subscription_status: 'inactive',
    subscription_price: price,
    SiteName: 'https://www.ndtvprofit.com'

  };
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(baseData);
  } else {
    console.error('Error: window.dataLayer is not available');
  }
};

export const subscriptionInitiate = (userData, pageType, headline, articleId, amount) => {
  const formattedTitle = window.location.pathname.substring(1) === 'subscription'
    ? 'subscription-page'
    : (pageType === 'story' ? (headline || 'NA') : 'NA');

  const baseData = {
    event: 'subscription_initiate',
    page_title: formattedTitle,
    tvc_article_id: pageType !== 'story' ? 'NA' : articleId,
    page_template: pageType !== 'story' ? 'section-page' : 'story',
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: userData?.isLoggedIn ? (userData?.isSubscriber ? 'subscriber' : 'non-subscriber') : 'anonymous-user',
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
    language_hit: 'english',
    Platform: isMobileOnly ? 'mobile' : 'desktop',
    subscription_status: 'inactive',
    subscription_price: amount,
    SiteName: 'https://www.ndtvprofit.com'

  };

  if (typeof window !== 'undefined' && window.dataLayer) {
    if (pageType === 'story') {
      const pageviewEvent = window.dataLayer.find((item) => item.event === 'pageview');
      if (pageviewEvent) {
        baseData.article_category = pageviewEvent.category || 'NA';
        baseData.article_tags = pageviewEvent.keywords || 'NA';
      } else {
        console.warn('Pageview event not found in dataLayer');
      }
    }
    const latestPlanSelection = [...window.dataLayer].reverse().find((item) => item.event === 'plan_selection');
    if (latestPlanSelection) {
      baseData.subscription_plan_duration = latestPlanSelection.cta_text || 'NA';
    } else {
      console.warn('Plan selection event not found in dataLayer');
    }
    window.dataLayer.push(baseData);
  } else {
    console.error('Error: window.dataLayer is not available');
  }
};

