/* eslint-disable max-len */
import React, { useRef, useState, useEffect } from 'react';
import SearchIcon from '../../../atoms/icons/search';
import './search-desktop.m.css';
import { debounce } from 'lodash';
import get from 'lodash/get';
import {
  AccountEditOutlineIcon,
  ChevronRightIcon,
  CloseIcon,
  PlayIcon,
} from '../../../atoms/material-icons';
import CardImage from '../../../atoms/cardimage';
import { getSection } from '../../../../helpers/utils';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

const SearchDesktop = (props) => {
  const inputRefDesktop = useRef();
  const suggestionRef = useRef();
  const [clickedLink, setClickedLink] = useState(false);
  const [submitMethod, setSubmitMethod] = useState('');
  const [PageTypeValue, setPageTypeValue] = useState(null);
  const formattedPageType =
    PageTypeValue === 'story-page'
      ? 'story'
      : PageTypeValue === 'home-page'
        ? 'home-page'
        : 'section-page';
  const [toggleSearch, setToggleSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [searchtext, setSearchtext] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [all_total, setAll_total] = useState(0);
  const [text_total, setText_total] = useState(0);
  const [video_total, setVideo_total] = useState(0);
  const [reports_total, setReports_total] = useState(0);
  const [suggestions, setSuggestion] = useState({
    all_stories: [],
    text_stories: [],
    research_reports: [],
    video_stories: [],
  });

  let allStories;
  let stories;
  let authors;
  let videos;
  let reports;

  let allTotal;
  let textTotal;
  let reportsTotal;
  let videosTotal;

  const inputValue = (inputRefDesktop?.current?.value || '').trim();
  const isInputEmpty = inputValue === '';

  if (suggestions.text_stories) {
    stories = suggestions?.text_stories;
    const uniqueAuthors = [];
    const AllAuthors = [
      ...suggestions?.text_stories,
      ...suggestions?.video_stories,
      ...suggestions?.research_reports,
    ]?.map((story) => story?.authors[0]);

    AllAuthors.forEach((author) => {
      var i = uniqueAuthors.findIndex((x) => x.name == author.name);
      if (i <= -1) {
        uniqueAuthors.push({ slug: author.slug, name: author.name, id: author.id });
      }
    });
    authors = uniqueAuthors;
  }

  if (suggestions.all_stories) {
    allStories = suggestions?.all_stories;
  }

  if (suggestions.video_stories) {
    videos = suggestions?.video_stories;
  }

  if (suggestions.research_reports) {
    reports = suggestions?.research_reports;
  }

  if (all_total) {
    allTotal = all_total;
  }

  if (text_total) {
    textTotal = text_total;
  }

  if (video_total) {
    videosTotal = video_total;
  }

  if (reports_total) {
    reportsTotal = reports_total;
  }
  useEffect(() => {
    inputRefDesktop.current.addEventListener('focus', () => setShowSuggestion(true));
    // eslint-disable-next-line prettier/prettier
    window.onclick = function (e) {
      if (
        !suggestionRef?.current?.contains(e?.target) &&
        e?.target !== inputRefDesktop?.current &&
        document.activeElement !== inputRefDesktop.current
      ) {
        setShowSuggestion(false);
      }
    };
    return () =>
      inputRefDesktop.current.removeEventListener('focus', () => setShowSuggestion(true));
  }, []);

  const getSuggestion = () => {
    setSearchtext(inputRefDesktop?.current?.value?.toLowerCase()?.trim());
    setLoading(true);
    fetch(
      `/api/v1/advanced-search?q=${inputRefDesktop?.current?.value?.toLowerCase()}&limit=15&fields=headline,authors,slug,id,sections&sort=latest-published`,
    )
      .then((res) => res.json())
      .then((res) => {
        setAll_total(res.total);
        setSuggestion((suggestions) => {
          return { ...suggestions, all_stories: res.items };
        });
      });
    fetch(
      `/api/v1/advanced-search?q=${inputRefDesktop?.current?.value?.toLowerCase()}&limit=15&fields=headline,authors,slug,id,sections&type=text,syndicated&exclude-section-id=21615&sort=latest-published`,
    )
      .then((res) => res.json())
      .then((res) => {
        setText_total(res.total);
        setSuggestion((suggestions) => {
          return { ...suggestions, text_stories: res.items };
        });
      });
    fetch(
      `/api/v1/advanced-search?q=${inputRefDesktop?.current?.value?.toLowerCase()}&limit=15&type=video&sort=latest-published`,
    )
      .then((res) => res.json())
      .then((res) => {
        setVideo_total(res.total);
        setSuggestion((suggestions) => {
          return { ...suggestions, video_stories: res.items };
        });
      });
    fetch(
      `/api/v1/advanced-search?q=${inputRefDesktop?.current?.value?.toLowerCase()}&limit=15&fields=headline,authors,slug,id&section-id=21615&sort=latest-published`,
    )
      .then((res) => res.json())
      .then((res) => {
        setReports_total(res.total);
        setSuggestion((suggestions) => {
          setLoading(false);
          return { ...suggestions, research_reports: res.items };
        });
      });
  };

  const getSuggestionDebounced = debounce(getSuggestion, 700);

  const handleSearchClose = () => {
    inputRefDesktop.current.value = '';
    setToggleSearch(false);
    setSuggestion({
      all_stories: [],
      text_stories: [],
      video_stories: [],
      research_reports: [],
    });
  };

  const handleSearchOpen = () => {
    setToggleSearch(true);
  };

  useEffect(() => {
    function pageTypeGetter() {
      if (typeof window !== 'undefined' && window.localStorage) {
        const pageTypeValue = window.localStorage.getItem('pageTypeValue');
        setPageTypeValue(pageTypeValue);
      }
    }
    pageTypeGetter();
  }, [toggleSearch]);

  useEffect(() => {
    if (toggleSearch) {
      inputRefDesktop?.current.focus();
    }
  }, [toggleSearch]);

  useEffect(() => {
    if (showSuggestion) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }

    if (showSuggestion && Object.values(suggestions).some((array) => array.length > 0)) {
      props.setSearchOpen(true);
    } else {
      props.setSearchOpen(false);
    }
  }, [showSuggestion, suggestions]);

  const tabs = ['ALL', 'STORIES', 'VIDEOS', 'REPORTS', 'AUTHORS']?.filter((item) => item !== null);
  const userData = useSelector((state) => get(state, ['userData']));

  const searchDataLayer = (name, userData, select_type, headline) => {
    const baseData = {
      event: 'search_term_entered',
      search_term: name || 'NA',
      select_type: select_type || 'NA',
      page_title: headline || 'NA',
      page_template: formattedPageType,
      user_id: userData?.userProfile?.userId || 'NA',
      user_status: '',
      login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
      language_hit: 'english',
      Platform: isMobile ? 'mobile' : 'desktop',
      SiteName: 'https://www.ndtvprofit.com'
    };

    if (userData?.isLoggedIn) {
      baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
    } else {
      baseData.user_status = 'anonymous-user';
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(baseData);
    }
  };
  const handleSearch = () => {
    const selectType = document.activeElement?.type === 'submit' ? 'search_icon' : 'enter';
    const typedText = inputRefDesktop?.current?.value;
    searchDataLayer(typedText, userData, selectType, 'NA');
  };

  const handleSearchItemClick = (headline) => {
    const typedText = inputRefDesktop?.current?.value;
    searchDataLayer(typedText, userData, 'suggestion click', headline);
  };

  return (
    <>
      <div
        className="desktop-only"
        styleName={`search-desktop ${toggleSearch ? 'searchBarOpen' : 'searchBarClose'}`}
      >
        <form action="/search" method="get" onSubmit={handleSearch}>
          <input
            ref={inputRefDesktop}
            name="q"
            type="text"
            onChange={getSuggestionDebounced}
            autoComplete="off"
            placeholder="Search here..."
          />
          {toggleSearch ? (
            <>
              {loading ? (
                <div styleName="loading-container">
                  <div styleName="loading" />
                </div>
              ) : (
                <div onClick={handleSearchClose} styleName="close-icon-search">
                  <CloseIcon color="#7e96a2" fontSize="15px" />
                </div>
              )}
            </>
          ) : null}
          <div styleName="search-btn-wrapper">
            <button type="submit" disabled={isInputEmpty} />
            <SearchIcon
              color="regent-gray"
              fontSize="15px"
              onClick={handleSearchOpen}
              className={toggleSearch ? 'pointer-none' : ''}
            />
          </div>
        </form>
      </div>
      {Object.values(suggestions).some((array) => array.length > 0) && showSuggestion && (
        <div styleName="search-results-container" ref={suggestionRef}>
          <div styleName="search-results-tabs">
            {tabs?.map((tab, index) => (
              <li
                onClick={() => setActiveTab(index)}
                styleName={activeTab === index ? 'active' : ''}
              >
                {tab}
              </li>
            ))}
          </div>
          <div
            styleName="suggestions-autofill-container"
            style={{
              transform: `translateX(-${activeTab * (100 / tabs?.length)}%)`,
              width: `${tabs?.length * 100}%`,
            }}
          >
            <div
              styleName="stories-tab"
              className="container"
              style={{ width: `${100 / tabs?.length}%` }}
            >
              <div styleName="total-results">{`Showing ${allStories?.length > 4 ? 4 : (allStories?.length ?? 0)
                } of ${allTotal ?? 0}`}</div>

              {allStories?.length > 0
                ? allStories.slice(0, 4).map((story) => (
                  <a
                    href={`/${story?.slug}`}
                    styleName="suggestionBox-text"
                    onClick={() => {
                      handleSearchItemClick(story?.headline);
                    }}
                  >
                    <span>{story?.headline}</span>
                    <span>{getSection(story)}</span>
                  </a>
                ))
                : null}
            </div>

            <div
              styleName="stories-tab"
              className="container"
              style={{ width: `${100 / tabs?.length}%` }}
            >
              <div styleName="total-results">{`Showing ${stories?.length > 4 ? 4 : (stories?.length ?? 0)
                } of ${textTotal ?? 0}`}</div>
              {stories?.length > 0
                ? stories.slice(0, 4).map((story) => (
                  <a
                    href={`/${story?.slug}`}
                    styleName="suggestionBox-text"
                    onClick={() => {
                      handleSearchItemClick(story?.headline);
                    }}
                  >
                    <span>{story?.headline}</span>
                    <span>{getSection(story)}</span>
                  </a>
                ))
                : null}
            </div>
            <div
              styleName="videos-tab"
              className="container"
              style={{ width: `${100 / tabs?.length}%` }}
            >
              <div styleName="total-results">{`Showing ${videos?.length > 4 ? 4 : (videos?.length ?? 0)
                } of ${videosTotal ?? 0}`}</div>
              {videos?.length > 0 &&
                videos?.slice(0, 4)?.map((story) => (
                  <a
                    href={`/${story?.slug}`}
                    styleName="suggestionBox-video"
                    onClick={() => handleSearchItemClick(story?.headline)}
                  >
                    <div styleName="video-thumb">
                      <CardImage
                        story={story}
                        imageType="image16x9"
                        aspectRatio={[16, 9]}
                        isLazy={true}
                      />
                      <span styleName="play-icon">
                        <PlayIcon size="14px" color="#2b2b2b" />
                      </span>
                    </div>
                    <span>{story?.headline}</span>
                  </a>
                ))}
            </div>
            <div
              styleName="reports-tab"
              className="container"
              style={{ width: `${100 / tabs?.length}%` }}
            >
              <div styleName="total-results">{`Showing ${reports?.length > 4 ? 4 : (reports?.length ?? 0)
                } of ${reportsTotal ?? 0}`}</div>
              {reports.length > 0 &&
                reports?.slice(0, 4)?.map((story) => (
                  <a
                    href={`/${story?.slug}`}
                    styleName="suggestionBox-report"
                    onClick={() => {
                      handleSearchItemClick(story?.headline);
                    }}
                  >
                    <span>{story?.authors[0]?.name}</span>
                    <span>{story?.headline}</span>
                  </a>
                ))}
            </div>
            <div
              styleName="authors-tab"
              className="container"
              style={{ width: `${100 / tabs.length}%` }}
            >
              <div styleName="total-results">{`Showing ${authors?.length > 8 ? 8 : (authors?.length ?? 0)
                } of ${authors?.length ?? 0}`}</div>
              {authors.length > 0
                ? authors?.slice(0, 8)?.map((author) => {
                  const name = author?.name?.split(' ');
                  const [firstname, ...lastname] = name ?? [];
                  return (
                    <a
                      href={`/author/${author.id}/${author.slug}`}
                      styleName="suggestionBox-author"
                      onClick={() => {
                        handleSearchItemClick(`${firstname} ${lastname.join('_')}`);
                      }}
                    >
                      <div>
                        <AccountEditOutlineIcon color="var(--sixth-color)" size="16px" />
                      </div>
                      <span>
                        <strong>{firstname} </strong>
                        {lastname}
                      </span>
                      <ChevronRightIcon size="22px" />
                    </a>
                  );
                })
                : null}
            </div>
          </div>

          <a
            styleName="view-all-stories-mobile"
            href={`/search?q=${searchtext}`}
            onClick={() => {
              searchDataLayer(searchtext, userData, 'View All Search Results');
            }}
          >
            <SearchIcon color="regent-gray" fontSize="15px" />
            View All Search Results
          </a>
        </div>
      )}
    </>
  );
};

export default SearchDesktop;
