import React, { useEffect, useState } from "react";

import { func, bool } from "prop-types";
import { connect, useSelector } from "react-redux";
import get from "lodash/get";

import { BQLink } from "../../atoms/bqlink";
import Button from "../../atoms/button";
import SupportIcon from "../../atoms/icons/support";
import LogoutIcon from "../../atoms/icons/logout";
import EyeIcon from "../../atoms/icons/eye";
import SubscriptionIcon from "../../atoms/icons/subscription";
import UserRoundIcon from "../../atoms/icons/user-round-icon";
import Icon from "@mdi/react";
import { mdiBookmarkMultiple, mdiChevronRight } from "@mdi/js";
import MorningIcon from "../../atoms/icons/good-morning-evening";
import AfternoonIcon from "../../atoms/icons/good-afternoon";
import NightIcon from "../../atoms/icons/good-night";
import { PATH } from "../../../constants";
import { getTodayDate, getUserInitials } from "../../../helpers/utils";

import "./member-menu.module.css";
import {
  getSSOLoginUrl,
  getSSORedirectUrl,
} from "../../../helpers/subscription-helper";
import { accountInteraction, signInClick } from '../../../helpers/datalayer-utils';

const DropdownMenu = ({
  isLoading,
  openUserModal,
  logout,
  watchlistVisibility,
  trackSubscribeClick,
  userProfile,
  subscriptionPage,
  isScrolled,
}) => {
  const [member, setMember] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [loginUrl, setLoginUrl] = useState("");
  const isSubscriber = useSelector((state) =>
    get(state, ["userData", "isSubscriber"], null)
  );
  const integrations = useSelector((state) =>
    get(state, ["qt", "config", "integrations"], {})
  );

  const SSO_LOGIN_URL = useSelector((state) =>
    get(state, ["qt", "config", "features", "subscription", "login_url"], null)
  );

  useEffect(() => {
    const url = new URL(window?.location?.href);
    getSSORedirectUrl(url).then((pageUrl) => {
      setRedirectUrl(pageUrl);
    });
  }, []);
  useEffect(() => {
    getSSOLoginUrl(
      integrations?.sso?.client_id,
      integrations?.sso?.client_secret,
      redirectUrl,
      SSO_LOGIN_URL
    ).then((pageUrl) => {
      setLoginUrl(pageUrl);
    });
  }, [redirectUrl]);
  useEffect(() => {
    if (userProfile) {
      const member = {
        firstName: userProfile?.fullName?.split(" ")[0],
        lastName:
          userProfile?.fullName?.split(" ")[
          userProfile.fullName.split(" ").length - 1
          ],
      };
      setMember(member);
    }
  }, [userProfile]);

  const getGreetingTime = () => {
    const currentHour = new Date().getHours();
    const greetingMessage =
      currentHour >= 6 && currentHour < 12
        ? "Morning"
        : currentHour >= 12 && currentHour < 18
          ? "Afternoon"
          : currentHour >= 18 && currentHour < 21
            ? "Evening"
            : currentHour >= 21 || currentHour < 6
              ? "Night"
              : "Day";
    return greetingMessage;
  };

  const formattedTime = getTodayDate(`h:mm aa 'IST'`);
  const greeting = getGreetingTime();

  const iconColor = subscriptionPage ? "brown" : "regent-gray1";
  const userData = useSelector((state) => get(state, ['userData']));
  const pageType = useSelector((state) => get(state, ['qt', 'pageType']));

  return (
    <ul
      styleName={`profile-menu ${subscriptionPage ? "theme-wrap" : ""} ${isScrolled ? "scroll-wrap" : ""
        }`}
    >
      <li>
        <div styleName="greeting-wrapper">
          <div styleName="greeting">
            {greeting === "Morning" && <MorningIcon color={iconColor} />}
            {greeting === "Afternoon" && <AfternoonIcon color={iconColor} />}
            {greeting === "Evening" && <MorningIcon color={iconColor} />}
            {greeting === "Night" && <NightIcon color={iconColor} />}
            <b styleName={`text ${subscriptionPage ? "theme-color" : ""}`}>
              Good {greeting} &nbsp;
            </b>
            <div styleName={`time ${subscriptionPage ? "theme-color" : ""}`}>
              {formattedTime}
            </div>
          </div>
        </div>
      </li>
      <li>
        <div styleName="user-greet-section">
          <div styleName="greeting">
            <div
              styleName={`user-initials ${!isSubscriber ? "basic-user" : ""}`}
            >
              <div styleName="inner-circle">
                {!!userProfile ? (
                  <span styleName="initials">{getUserInitials(userProfile) || "R"}</span>
                ) : (
                  <UserRoundIcon
                    color={"regent-gray"}
                    fontSize="28px"
                    styleName="user-icon-default"
                  />
                )}
                {!!userProfile && (!!userProfile.userId) && (
                  <div
                    styleName={`sub-tag ${isSubscriber ? "premium-tag" : "basic-tag"
                      }`}
                  >
                    {isSubscriber ? <span>✦ MEMBER</span> : <span>BASIC</span>}
                  </div>
                )}
              </div>
            </div>
            <div styleName="name-ctx">
              {!!userProfile ? (
                <div onClick={() => accountInteraction(userData, pageType, 'header', 'view profile')}>
                  <div
                    styleName={`name ${subscriptionPage ? "theme-color" : ""}`}
                  >
                    {userProfile?.fullName || getUserInitials(userProfile) || 'Profit Reader'}
                  </div>
                  <BQLink
                    href="/my-account/profile?src=menu_user"
                    styleName={`profile ${!isSubscriber ? "basic-profile" : ""
                      } ${subscriptionPage ? "theme-color" : ""}`}
                  >
                    <span>View Profile</span>
                    <Icon path={mdiChevronRight} size="16px" />
                  </BQLink>
                </div>
              ) : (
                <React.Fragment>
                  <div styleName="name default-name">Hello Reader</div>
                  <div onClick={() => signInClick(userData, pageType, 'Sign In / Register', 'header')}>
                    <BQLink href={loginUrl} target="_self">
                      <div styleName="sign-in">
                        Sign In / Register&nbsp;
                        <Icon path={mdiChevronRight} size={1} />
                      </div>
                    </BQLink>
                  </div>
                </React.Fragment>
              )}
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </li>
      {isSubscriber && (
        <li onClick={() => accountInteraction(userData, pageType, 'header', 'subscription')}>

          <BQLink
            id="subscribe"
            dmptrack="true"
            href="/my-account/subscription?src=subscribe"
            styleName="menu-link"
            onClick={() => trackSubscribeClick(global.location.pathname)}
          >
            <div>
              <SubscriptionIcon fontSize="16px" color={subscriptionPage ? 'brown' : 'submarine'} />
            </div>
            Subscription
          </BQLink>
        </li>
      )}
      {watchlistVisibility && member && (
        <li onClick={() => accountInteraction(userData, pageType, 'header', 'watchlist')}>
          <BQLink
            id="my-watchlist"
            href={`${PATH.MYBQ_PAGE}${PATH.MYACCOUNT_WATCHLIST}?src=wtchlist`}
            styleName="menu-link"
          >
            <div>
              <EyeIcon fontSize="16px" color={subscriptionPage ? 'var(--brown)' : 'var(--submarine)'} />
            </div>
            Watchlist
          </BQLink>
        </li>
      )}
      {member && (
        <li onClick={() => accountInteraction(userData, pageType, 'header', 'bookmarks')}>
          <BQLink
            id="my-bookmarks"
            href="/my-account/bookmarks?src=bookmarks"
            styleName="menu-link"
          >
            <div>
              <Icon path={mdiBookmarkMultiple} size="18px" color={subscriptionPage ? '#B7A285' : '#c7cdd1'} />
            </div>
            Bookmarks
          </BQLink>
        </li>
      )}
      {!isLoading && !member && (
        <li>
          <Button
            variant="transparent"
            id="login"
            href="#"
            styleName="menu-link"
            onClick={openUserModal}
          >
            Login
          </Button>
        </li>
      )}
      {member && (
        <li onClick={() => accountInteraction(userData, pageType, 'header', 'Need help ?')}>
          <BQLink
            variant="transparent"
            id="support"
            styleName="menu-link"
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:profitsupport@ndtv.com"
          >
            <div>
              <SupportIcon color={subscriptionPage ? 'brown' : 'submarine'} />
            </div>
            Need Help ?
          </BQLink>
        </li>
      )}
      {member && (
        <li onClick={() => accountInteraction(userData, pageType, 'header', 'logout')}>
          <Button
            variant="transparent"
            id="logout"
            href="#"
            styleName="menu-link logout-link"
            onClick={logout}
          >
            <div>
              <LogoutIcon color={subscriptionPage ? 'brown' : 'submarine'} fontSize="16px" />
            </div>
            Logout
          </Button>
        </li>
      )}
    </ul>
  );
};

DropdownMenu.propTypes = {
  openUserModal: func.isRequired,
  isLoading: bool.isRequired,
  logout: func.isRequired,
  hideSubscribe: bool,
  watchlistVisibility: bool,
  trackSubscribeClick: func,
  subscriptionPage: bool,
};
DropdownMenu.defaultProps = {
  subscriptionPage: false,
};

const mapStateToProps = (state) => ({
  userProfile: get(state, ["userData", "userProfile"], null),
});

export default connect(mapStateToProps, {})(DropdownMenu);
