import React, { useEffect, useState } from 'react';
import { array } from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import './global-header.m.css';
import { makeApiCall } from '../../../../helpers/utils';
import { isMobile } from 'react-device-detect';

const defaultData = [
  {
    Title: 'BUSINESS',
    Link: '/',
  },
  {
    Title: 'बिजनेस',
    Link: 'https://hindi.ndtvprofit.com',
  },
];

const GlobalHeader = ({ features, pageType }) => {
  const [data, setData] = useState([]);
  const userData = useSelector((state) => get(state, ['userData']));
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  useEffect(() => {
    makeApiCall(
      features?.ndtv_global_nav?.url || 'https://edata.ndtv.com/feeds/hp/Globalnavhp23_23.json',
      'GET',
      {},
      {},
      { mode: 'cors' },
    )
      .then((response) => {
        const newList = response
          .filter((list) => list.Title.toLowerCase() !== 'profit')
          ?.map((item) => {
            if (item.Title === 'TECH') {
              return [item, ...defaultData];
            }
            return item;
          })
          .flat();
        setData(newList);
      })
      .catch((error) => {
        console.error('Error during fetch:', error);
      });
  }, []);

  const handleClick = (data, formattedPageType) => {
    const titleMapping = {
      हिंदी: 'hindi',
      बिजनेस: 'business_hindi',
    };

    const baseData = {
      event: 'global_interaction',
      cta_text: titleMapping[data?.Title] || (data?.Title?.trim()?.replace(/\s+/g, '_') || 'NA'),
      page_template: formattedPageType || 'NA',
      user_id: userData?.userProfile?.userId || 'NA',
      user_status: 'NA',
      login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
      language_hit: 'english',
      Platform: isMobile ? 'mobile' : 'desktop',
      SiteName: 'https://www.ndtvprofit.com'
    };

    if (userData?.isLoggedIn) {
      baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
    } else {
      baseData.user_status = 'anonymous-user';
    }

    if (typeof window !== 'undefined' && window.localStorage) {
      window.dataLayer.push(baseData);
    } else {
      console.error('Error: window.dataLayer is not available');
    }
  };

  return (
    <div styleName="menu-wrapper" className="desktop-only">
      <div styleName="main-menu">
        {data?.length > 0
          ? data.map((item) => {
            return (
              <a
                href={
                  `${item.Link}?utm_source=profit&utm_medium=referral&utm_campaign=globnav` || '/'
                }
                styleName={item.Title === 'BUSINESS' ? 'select-list' : 'menu-item'}
                target={item.Title === 'BUSINESS' ? '_self' : '_blank'}
                key={item.id}
                onClick={() => handleClick(item, formattedPageType)}
              >
                {item.Title}
              </a>
            );
          })
          : new Array(15).fill(null).map((element, i) => {
            return <span styleName="menu-item-skeleton" key={i + element} />;
          })}
      </div>
    </div>
  );
};

GlobalHeader.propTypes = {
  menu: array.isRequired,
};

GlobalHeader.defaultProps = {
  menu: [],
};

const mapStateToProps = (state) => ({
  pageType: get(state, ['qt', 'pageType'], ''),
});

export default connect(mapStateToProps)(GlobalHeader);
