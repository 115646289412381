import {
  renderComponent,
  renderIsomorphicComponent
} from '@quintype/framework/client/start';
import { pickComponent } from '../isomorphic/pick-component';
import Footer from '../isomorphic/components/rows/new-footer/new-footer';
import Header from '../isomorphic/components/rows/header/header';
import { LoadingIndicatorComponent } from '../isomorphic/components/atoms/loading-indicator';
import OfflineNotification from '../isomorphic/components/rows/offline-notification';
import TrafficTrack from '../isomorphic/components/atoms/traffic-track';
import DisplayAds from '../isomorphic/components/atoms/display-ads';
import { ToasterMessage } from '../isomorphic/components/atoms/toaster-message';

export function preRenderApplication(store) {
  if (!store.getState().qt.hideHeaderFooter) {
    renderComponent(Header, 'header', store, {
      hydrate: true,
    });
    renderComponent(Footer, 'footer', store, {
      hydrate: true,
    });
  }

  if (window.location.pathname.includes('widget')) {
    const urlParams = new URLSearchParams(window.location.search);
    const dm = urlParams.get('dm');
    const disabledm = urlParams.get('disabledm');

    if (disabledm === "1") {
      const body = document.getElementsByTagName('body')[0];
      body.style.backgroundColor = "#FFFFFF";
    } else if (dm === "1") {
      const body = document.getElementsByTagName('body')[0];
      body.style.backgroundColor = "#2B2B2B";
    }
  }
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  if (!store.getState().qt.disableIsomorphicComponent) {
    renderIsomorphicComponent('container', store, pickComponent, {
      hydrate: !global.qtLoadedFromShell,
    });
    renderComponent(LoadingIndicatorComponent, 'loading-indicator', store, {
      hydrate: true,
    });
    renderComponent(TrafficTrack, 'traffic-track', store, {
      hydrate: true,
    });
    renderComponent(OfflineNotification, 'offline-notification', store, {
      hydrate: true,
    });
    renderComponent(DisplayAds, 'display-ads', store, { hydrate: true });
    renderComponent(ToasterMessage, 'toaster-message', store);

    if (window.location.pathname.includes('widget')) {
      const id = document.getElementById('container');
      id.classList.add('custom-widget');
    }
  }
}
