import React, { useEffect, useState, useRef, useCallback } from 'react';
import { any, string } from 'prop-types';

import CloseIcon from '../icons/close';
import SearchIcon from '../icons/search';
import { debounce } from 'lodash';
import './mobile-search-bar.m.css';
import CardImage from '../cardimage';
import { AccountEditOutlineIcon, ChevronRightIcon, PlayIcon } from '../material-icons';
import { getSection } from '../../../helpers/utils';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';

function MobileSearchBar() {
  const inputRef = useRef();
  const [PageTypeValue, setPageTypeValue] = useState('');
  const formattedPageType =
    PageTypeValue === 'story-page'
      ? 'story'
      : PageTypeValue === 'home-page'
        ? 'home-page'
        : 'section-page';
  const [searchtext, setSearchtext] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [all_total, setAll_total] = useState(0);
  const [text_total, setText_total] = useState(0);
  const [video_total, setVideo_total] = useState(0);
  const [reports_total, setReports_total] = useState(0);
  const [suggestions, setSuggestion] = useState({
    all_stories: [],
    text_stories: [],
    research_reports: [],
    video_stories: [],
  });
  const [ref, inView] = useInView();

  let allStories;
  let stories;
  let authors;
  let videos;
  let reports;

  let allTotal;
  let textTotal;
  let reportsTotal;
  let videosTotal;

  if (suggestions.text_stories) {
    stories = suggestions?.text_stories;
    let uniqueAuthors = [];
    let AllAuthors = [
      ...suggestions?.text_stories,
      ...suggestions?.video_stories,
      ...suggestions?.research_reports,
    ].map((story) => story.authors[0]);

    AllAuthors.forEach((author) => {
      var i = uniqueAuthors.findIndex((x) => x.name == author.name);
      if (i <= -1) {
        uniqueAuthors.push({ slug: author.slug, name: author.name, id: author.id });
      }
    });

    authors = uniqueAuthors;
  }

  if (suggestions.all_stories) {
    allStories = suggestions?.all_stories;
  }

  if (suggestions.video_stories) {
    videos = suggestions?.video_stories;
  }

  if (suggestions.research_reports) {
    reports = suggestions.research_reports;
  }

  if (all_total) {
    allTotal = all_total;
  }

  if (text_total) {
    textTotal = text_total;
  }

  if (video_total) {
    videosTotal = video_total;
  }

  if (reports_total) {
    reportsTotal = reports_total;
  }

  useEffect(() => {
    if (openSearch) {
      document.body.style.overflowY = 'hidden';
      inputRef.current.focus();
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }, [openSearch]);
  useEffect(() => {
    function pageTypeGetter() {
      if (typeof window !== 'undefined' && window.localStorage) {
        const pageTypeValue = window.localStorage.getItem('pageTypeValue');
        setPageTypeValue(pageTypeValue);
      }
    }
    pageTypeGetter();
  }, [openSearch]);

  const closeSearch = () => {
    setOpenSearch(false);
  };

  const clearSearch = () => {
    setSearchtext('');
    inputRef.current.value = '';
    setSuggestion({
      all_stories: [],
      text_stories: [],
      video_stories: [],
      research_reports: [],
    });
    setAll_total(0);
    setText_total(0);
    setReports_total(0);
    setVideo_total(0);
  };

  const getSuggestion = () => {
    setSearchtext(inputRef?.current?.value?.toLowerCase()?.trim());
    setLoading(true);
    fetch(
      `/api/v1/advanced-search?q=${inputRef?.current?.value?.toLowerCase()}&limit=15&fields=headline,authors,slug,id,sections`,
    )
      .then((res) => res.json())
      .then((res) => {
        setAll_total(res.total);
        setSuggestion((suggestions) => {
          return { ...suggestions, all_stories: res.items };
        });
      });
    fetch(
      `/api/v1/advanced-search?q=${inputRef?.current?.value?.toLowerCase()}&limit=15&fields=headline,authors,slug,id,sections&type=text,syndicated&exclude-section-id=21615&sort=latest-published`,
    )
      .then((res) => res.json())
      .then((res) => {
        setText_total(res.total);
        setSuggestion((suggestions) => {
          return { ...suggestions, text_stories: res.items };
        });
      });
    fetch(
      `/api/v1/advanced-search?q=${inputRef?.current?.value?.toLowerCase()}&limit=15&type=video&sort=latest-published`,
    )
      .then((res) => res.json())
      .then((res) => {
        setVideo_total(res.total);
        setSuggestion((suggestions) => {
          return { ...suggestions, video_stories: res.items };
        });
      });
    fetch(
      `/api/v1/advanced-search?q=${inputRef?.current?.value?.toLowerCase()}&limit=15&fields=headline,authors,slug,id&section-id=21615&sort=latest-published`,
    )
      .then((res) => res.json())
      .then((res) => {
        setReports_total(res.total);
        setSuggestion((suggestions) => {
          setLoading(false);
          return { ...suggestions, research_reports: res.items };
        });
      });
  };

  const getSuggestionDebounced = useCallback(debounce(getSuggestion, 700), []);
  const tabs = ['ALL', 'STORIES', 'VIDEOS', 'REPORTS', 'AUTHORS']?.filter((item) => item !== null);
  const userData = useSelector((state) => get(state, ['userData']));

  const searchDataLayer = (name, userData, select_type, headline) => {
    const baseData = {
      event: 'search_term_entered',
      search_term: name || 'NA',
      select_type: select_type || 'NA',
      page_title: headline || 'NA',
      page_template: formattedPageType,
      user_id: userData?.userProfile?.userId || 'NA',
      user_status: 'NA',
      login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
      language_hit: 'english',
      Platform: isMobile ? 'mobile' : 'desktop',
      SiteName: 'https://www.ndtvprofit.com'
    };

    if (userData?.isLoggedIn) {
      baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
    } else {
      baseData.user_status = 'anonymous-user';
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(baseData);
    } else {
      console.error('Error: window.dataLayer is not available');
    }
  };
  const handleSearch = () => {
    const searchText = inputRef.current.value;
    searchDataLayer(searchText, userData, 'enter');
  };
  const handleSearchItemClick = (headline) => {
    const searchText = inputRef.current.value;
    searchDataLayer(searchText, userData, 'suggestion click', headline);
  };

  return (
    <div className="mobile-only" ref={ref}>
      <div
        onClick={() => {
          inputRef.current.focus();
          setOpenSearch(true);
        }}
        styleName="search-icon-mobile"
      >
        <SearchIcon fontSize="22px" />
      </div>
      <div styleName="search" style={{ transform: `translateX(${openSearch ? '0' : '110vw'})` }}>
        <div styleName="container">
          <div styleName="inputContainer">
            <SearchIcon fontSize="22px" color="regent-gray" />
            <form action="/search" method="get" onSubmit={handleSearch}>
              <input
                ref={inputRef}
                name="q"
                type="search"
                onChange={getSuggestionDebounced}
                autoComplete="off"
                variant="primary"
                placeholder="Search here..."
                id="input-search-box"
                styleName="global-search-mob"
              />
            </form>

            {loading ? (
              <div styleName="loading-container">
                <div styleName="loading" />
              </div>
            ) : searchtext ? (
              <div styleName="clear-btn" onClick={clearSearch}>
                Clear
              </div>
            ) : (
              <div onClick={closeSearch}>
                <CloseIcon color="regent-gray" />
              </div>
            )}
          </div>
          {!Object.values(suggestions).some((array) => array.length > 0) ? (
            <div styleName="hide-tabs" />
          ) : (
            <div styleName="search-results-tabs" className="container">
              {tabs.map((tab, index) => (
                <li
                  onClick={() => setActiveTab(index)}
                  styleName={activeTab === index ? 'active' : ''}
                  key={index}
                >
                  {tab}
                </li>
              ))}
            </div>
          )}
          {!Object.values(suggestions).some((array) => array.length > 0) ? (
            <div styleName="search-initial-state-img">
              {inView && (
                <img
                  src="/icons/searchUFO.png"
                  style={{ width: '231px', height: '303px' }}
                  alt="searchUFO"
                />
              )}
            </div>
          ) : (
            <div
              styleName="suggestions-autofill-container"
              style={{
                transform: `translateX(-${activeTab * (100 / tabs?.length)}%)`,
                width: `${tabs?.length * 100}%`,
              }}
            >
              <div
                styleName="stories-tab"
                className="container"
                style={{ width: `${100 / tabs?.length}%` }}
              >
                <div
                  styleName={`total-results ${
                    Object.values(suggestions).some((array) => array.length > 0)
                      ? 'show-total'
                      : 'hide-total'
                  }`}
                >{`Showing ${allStories?.length > 7 ? 7 : (allStories?.length ?? 0)} of ${
                  allTotal ?? 0
                }`}</div>
                {allStories?.length > 0
                  ? allStories.slice(0, 7).map((story, index) => (
                      <a
                        href={`/${story?.slug}`}
                        styleName="suggestionBox-text"
                        onClick={() => {
                          handleSearchItemClick(story?.headline);
                        }}
                        key={index}
                      >
                        <span>{story?.headline}</span>
                        <span>{getSection(story)}</span>
                      </a>
                    ))
                  : null}
              </div>
              <div
                styleName="stories-tab"
                className="container"
                style={{ width: `${100 / tabs?.length}%` }}
              >
                <div
                  styleName={`total-results ${
                    Object.values(suggestions).some((array) => array.length > 0)
                      ? 'show-total'
                      : 'hide-total'
                  }`}
                >{`Showing ${stories?.length > 7 ? 7 : (stories?.length ?? 0)} of ${
                  textTotal ?? 0
                }`}</div>
                {stories?.length > 0
                  ? stories.slice(0, 7).map((story, index) => (
                      <a
                        href={`/${story?.slug}`}
                        styleName="suggestionBox-text"
                        onClick={() => {
                          handleSearchItemClick(story?.headline);
                        }}
                        key={index}
                      >
                        <span>{story?.headline}</span>
                        <span>{getSection(story)}</span>
                      </a>
                    ))
                  : null}
              </div>
              <div
                styleName="videos-tab"
                className="container"
                style={{ width: `${100 / tabs?.length}%` }}
              >
                <div
                  styleName={`total-results ${
                    Object.values(suggestions).some((array) => array.length > 0)
                      ? 'show-total'
                      : 'hide-total'
                  }`}
                >{`Showing ${videos?.length > 6 ? 6 : (videos?.length ?? 0)} of ${
                  videosTotal ?? 0
                }`}</div>
                {videos?.length > 0
                  ? videos?.slice(0, 6)?.map((story, index) => (
                      <a
                        styleName="suggestionBox-video"
                        href={`/${story?.slug}`}
                        onClick={() => {
                          handleSearchItemClick(story?.headline);
                        }}
                        key={index}
                      >
                        <div styleName="video-thumb">
                          <CardImage
                            story={story}
                            imageType="image16x9"
                            aspectRatio={[16, 9]}
                            isLazy={true}
                          />
                          <span styleName="play-icon">
                            <PlayIcon size="14px" color="#2b2b2b" />
                          </span>
                        </div>
                        <span>{story?.headline}</span>
                      </a>
                    ))
                  : null}
              </div>
              <div
                styleName="reports-tab"
                className="container"
                style={{ width: `${100 / tabs?.length}%` }}
              >
                <div
                  styleName={`total-results ${
                    Object.values(suggestions).some((array) => array.length > 0)
                      ? 'show-total'
                      : 'hide-total'
                  }`}
                >{`Showing ${reports?.length > 7 ? 7 : (reports?.length ?? 0)} of ${
                  reportsTotal ?? 0
                }`}</div>
                {reports.length > 0
                  ? reports?.slice(0, 7)?.map((story, index) => (
                      <a
                        href={`/${story?.slug}`}
                        styleName="suggestionBox-report"
                        onClick={() => {
                          handleSearchItemClick(story?.headline);
                        }}
                        key={index}
                      >
                        <span>{story?.authors[0]?.name}</span>
                        <span>{story?.headline}</span>
                      </a>
                    ))
                  : null}
              </div>
              <div
                styleName="authors-tab"
                className="container"
                style={{ width: `${100 / tabs?.length}%` }}
              >
                <div
                  styleName={`total-results ${
                    Object.values(suggestions).some((array) => array.length > 0)
                      ? 'show-total'
                      : 'hide-total'
                  }`}
                >{`Showing ${
                  authors?.length > 13 ? 13 : (authors?.length ?? 0)
                } of ${authors?.length ?? 0}`}</div>
                {authors.length > 0
                  ? authors.slice(0, 13).map((author) => {
                      let name = author?.name?.split(' ');
                      const [firstname, ...lastname] = name ?? [];
                      return (
                        <a
                          href={`/author/${author.id}/${author.slug}`}
                          styleName="suggestionBox-author"
                          onClick={() => {
                            handleSearchItemClick(`${firstname} ${lastname.join('_')}`);
                          }}
                        >
                          <div>
                            <AccountEditOutlineIcon color="var(--sixth-color)" size="18px" />
                          </div>
                          <span>
                            <strong>{firstname} </strong>
                            {lastname}
                          </span>
                          <ChevronRightIcon size="25px" />
                        </a>
                      );
                    })
                  : null}
              </div>
            </div>
          )}
          <div
            styleName={`view-all-btn-container ${
              Object.values(suggestions).some((array) => array.length > 0)
                ? 'show-view-all'
                : 'hide-view-all'
            }`}
            onClick={() => {
              searchDataLayer(searchtext, userData, 'View All Search Results');
            }}
          >
            <a styleName="view-all-stories-mobile" href={`/search?q=${searchtext}`}>
              <SearchIcon color="regent-gray" fontSize="15px" />
              View All Search Results
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

MobileSearchBar.propTypes = {
  mobileSearchBarDisplay: string,
  setMobileSearchBarDisplay: any,
};

export default MobileSearchBar;
