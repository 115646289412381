import React, { useEffect, useState } from 'react';
import { func, bool, string, object, number } from 'prop-types';
import NotificationsIcon from '../../atoms/icons/notifications-icon';
import BQNotifications from '../../../helpers/notifications-helper';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import '../notifications.m.css';
import { isMobile } from 'react-device-detect';

const NotificationButton = ({ togglePopover, popoverState, member, collectionSlug, syncItervalTime, maxCount, defaultCount }) => {
  const [count, setCount] = useState(0);
  const [defaultCountFlag, setDefaultCountFlag] = useState(false);

  const userData = useSelector((state) => get(state, ['userData']));
  const pageType = useSelector((state) => get(state, ['qt', 'pageType']));

  useEffect(() => {
    syncNotifications();
  }, [member]);

  const handleClick = () => {
    togglePopover();
    if (count) {
      setCount(0);
      if (member && member.uid) {
        BQNotifications.acknowledge(member.uid);
      }
    }

    const baseData = {
      event: 'notification_interaction',
      page_template: pageType,
      user_id: userData?.userProfile?.userId || 'NA',
      user_status: 'NA',
      login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
      language_hit: 'english',
      Platform: isMobile ? 'mobile' : 'desktop',
      SiteName: 'https://www.ndtvprofit.com'
    };

    if (userData?.isLoggedIn) {
      baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
    } else {
      baseData.user_status = 'anonymous-user';
    }

    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(baseData);
    } else {
      console.error('Error: window.dataLayer is not available');
    }
  };

  const syncNotifications = () => {
    if (count < maxCount) {
      if (member) {
        const currentCount = defaultCountFlag ? 0 : count;
        if (defaultCountFlag) {
          setDefaultCountFlag(false);
          setCount(0);
        }

        BQNotifications.sync(member, collectionSlug, currentCount)
          .then(newCount => {
            if (newCount !== undefined) setCount(newCount);
          })
          .catch(error => console.error(error));
      } else {
        if (!defaultCountFlag) {
          setCount(defaultCount);
          setDefaultCountFlag(true);
        }
      }
    }
  };

  return (
    <div
      data-testid="notification-button"
      styleName={`button-holder ${popoverState ? 'active' : ''}`}
      onClick={handleClick}
    >
      <NotificationsIcon color="regent-gray" fontSize="19px" />
    </div>
  );
};

NotificationButton.propTypes = {
  togglePopover: func.isRequired,
  popoverState: bool.isRequired,
  member: object,
  collectionSlug: string.isRequired,
  syncItervalTime: number.isRequired,
  maxCount: number.isRequired,
  defaultCount: number
};

export default NotificationButton;
