/* eslint-disable max-len */
import wretch from 'wretch';
import CryptoJS from 'crypto-js';
import { get, uniqBy, isEmpty, capitalize } from 'lodash';
import { differenceInDays, endOfDay, getTime } from 'date-fns';
import { format, formatInTimeZone } from 'date-fns-tz';
import { DATE_FORMATS } from '../../constants';
import { checkForSubscription, setTokens } from '../subscription-helper';
import { USER_LOGGEDIN, USER_PROFILE, USER_SUBSCRIBER } from '../../../store/actions';
import { getCardText, getCompleteTextWithoutStringParsing } from '../story-helpers';
import toast from 'react-hot-toast';
import React from 'react';
import { loginSignupSuccess } from '../datalayer-utils';

export const newsArticleSchema = (story, config, staticConfig) => {
  const structureDataConfig = staticConfig?.publisher?.structure_data;
  const imageObject = {
    '@type': 'ImageObject',
    url: `https://media.assettype.com/${story['hero-image-s3-key']}?w=1200&auto=format%2Ccompress&format=webp&q=60&rect=0%2C0%2C3500%2C1969&amp;`,
    height: 675,
    width: 1200,
  };

  const newsArticleSchema = {
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': story.url,
    },
    image: imageObject,
    datePublished: formatInTimeZone(
      story['published-at'],
      'Asia/Kolkata',
      DATE_FORMATS.DATE_TIME_ZONE,
    ),
    dateModified: formatInTimeZone(
      story['updated-at'],
      'Asia/Kolkata',
      DATE_FORMATS.DATE_TIME_ZONE,
    ),
    author: {
      '@type':
        structureDataConfig.author_org.includes(story['author-name']) ||
        (story?.sections[0].slug === 'research-reports' && story?.['author-name'] !== 'Usha Kunji')
          ? 'Organization'
          : 'Person',
      name: story['author-name'],
      url: `/author/${story.authors[0].id}/${story.authors[0].slug}`,
    },
    publisher: {
      '@type': 'NewsMediaOrganization',
      name: 'NDTV Profit',
      logo: {
        '@type': 'ImageObject',
      },
    },
    description: story.seo['meta-description'],
  };
  if (story?.access === 'login') {
    newsArticleSchema.hasPartValue = getCompleteTextWithoutStringParsing(story, []);
  }
  return newsArticleSchema;
};

export const videoStorySchema = (story) => {
  const videoSchema = {
    headline: story?.headline,
    image: `https://media.assettype.com/${story?.['hero-image-s3-key']}`,
    url: story?.url,
    datePublished: format(new Date(story?.['published-at']), "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone: 'Asia/Kolkata',
    }),
    dateModified: format(new Date(story?.['updated-at']), "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone: 'Asia/Kolkata',
    }),
    dateCreated: format(new Date(story?.['created-at']), "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone: 'Asia/Kolkata',
    }),
    description: story?.headline,
    name: story?.headline,
    thumbnailUrl: `https://media.assettype.com/${story?.['hero-image-s3-key']}`,
    uploadDate: format(new Date(story?.['published-at']), "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone: 'Asia/Kolkata',
    }),
  };
  return videoSchema;
};

export const liveBlogSchema = (story, staticConfig) => {
  const structureDataConfig = staticConfig?.publisher?.structure_data;
  const liveBlogUpdate = story.cards.map((card) => ({
    '@type': 'BlogPosting',
    articleBody: getCardText(card),
    dateModified: '',
    datePublished: '',
    publisher: {
      '@type': 'NewsMediaOrganization',
      name: 'NDTV Profit',
      url: 'https://www.ndtvprofit.com',
      logo: {
        '@type': 'ImageObject',
        url: structureDataConfig?.logo_url,
        width: structureDataConfig?.logo_width,
        height: structureDataConfig?.logo_height,
      },
    },
    headline: card['story-elements'][0].text,
    image: card?.metadata?.['social-share']?.image?.url,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': story.url,
    },
    author: {
      '@type':
        structureDataConfig.author_org.includes(story['author-name']) ||
        (story?.sections[0].slug === 'research-reports' && story?.['author-name'] !== 'Usha Kunji')
          ? 'Organization'
          : 'Person',
      name: story['author-name'],
      url: `/author/${story.authors[0].id}/${story.authors[0].slug}`,
    },
  }));
  const liveSchema = {
    headline: story?.headline,
    description: story?.seo['meta-description'] || story?.summary || story?.headline,
    datePublished: formatInTimeZone(
      story['content-created-at'],
      'Asia/Kolkata',
      DATE_FORMATS.DATE_TIME_ZONE,
    ),
    url: story.url,
    coverageStartTime: format(new Date(story['content-created-at']), "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone: 'Asia/Kolkata',
    }),
    coverageEndTime: format(
      endOfDay(new Date(story['content-created-at'])),
      "yyyy-MM-dd'T'HH:mm:ssXXX",
      {
        timeZone: 'Asia/Kolkata',
      },
    ),
    dateModified: formatInTimeZone(
      story['content-updated-at'],
      'Asia/Kolkata',
      DATE_FORMATS.DATE_TIME_ZONE,
    ),
    author: {
      '@type':
        structureDataConfig.author_org.includes(story['author-name']) ||
        (story?.sections[0].slug === 'research-reports' && story?.['author-name'] !== 'Usha Kunji')
          ? 'Organization'
          : 'Person',
      name: story['author-name'],
      url: `/author/${story.authors[0].id}/${story.authors[0].slug}`,
    },
    liveBlogUpdate: JSON.stringify(liveBlogUpdate),
  };

  return liveSchema;
};

export const siteNavSchema = (menus) => {
  const schema = {
    Name: JSON.stringify(
      ['Home Page'].concat(menus.items.map((menu) => menu['section-name'] || menu.title)),
    ),
    URL: JSON.stringify(
      ['https://www.ndtvprofit.com'].concat(menus.items.map((menu) => menu?.url)),
    ),
  };
  return schema;
};

export const breadcrumbSchema = (story) => {
  const breadcrumblist = story?.slug?.split('/')?.map((item) => capitalize(item)) || [];
  const schema = [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://www.ndtvprofit.com',
    },
  ];
  if (story['story-template'] === 'video' || story['story-template'] === 'ndtv-video') {
    schema.push(
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Videos',
        item: 'https://www.ndtvprofit.com/videos',
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: story.sections[0]['display-name'] || story.sections[0].name,
        item: story.sections[0]['section-url'],
      },
      {
        '@type': 'ListItem',
        position: 4,
        name: story.headline,
        item: story.url,
      },
    );
  } else {
    schema.push(
      {
        '@type': 'ListItem',
        position: 2,
        name: breadcrumblist[0],
        item: `https://www.ndtvprofit.com/${breadcrumblist[0]}`.toLowerCase(),
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: story.headline,
        item: `https://www.ndtvprofit.com/amp/${story.slug}`,
      },
    );
  }

  return JSON.stringify(schema);
};

export function getSyndicatedFrom(story) {
  return get(story, ['metadata', 'story-attributes', 'syndicatedfrom', 0]);
}

export function truncate(str, length) {
  const suffix = '...';
  return str.length < length ? str : str.substring(0, length - suffix.length) + suffix;
}
export function copyText(data = '') {
  const elem = document.createElement('textarea');
  elem.value = data;
  elem.setAttribute('readonly', '');
  elem.style.position = 'absolute';
  elem.style.left = '-9999px';
  document.body.appendChild(elem);
  elem.select();
  document.execCommand('copy');
  document.body.removeChild(elem);
}
export function isNotFillerStory(story) {
  const syndicatedFrom = getSyndicatedFrom(story);

  if (!syndicatedFrom) return true;

  const isFiller = syndicatedFrom.toLowerCase();
  return isFiller !== 'filler' && isFiller !== 'fillers';
}

export function skipTextElement(element, skipWords) {
  if (element.type === 'text') {
    return element.text.match(skipWords) === null;
  }
  return true;
}
/* eslint-disable */
export function slugify(string) {
  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}
/* eslint-enable */
export function syndicatedSource(story) {
  const syndicatedFrom = getSyndicatedFrom(story);

  if (syndicatedFrom === 'thequint') {
    return 'TheQuint';
  } else if (syndicatedFrom === 'bloomberg') {
    return 'Bloomberg';
  } else if (syndicatedFrom === 'vidible') {
    return 'vidible';
  } else if (syndicatedFrom === 'businessweek') {
    return 'businessweek';
  }
  return 'BQ';
}

export function openLinkInNewTab(selector) {
  const linkList = document.querySelectorAll(selector);
  for (let i = 0; i < linkList.length; i++) {
    linkList[i].target = '_blank';
  }
}

export function getTitleElement(elements) {
  return elements.find((element) => element.type === 'title');
}

export function titleText(card) {
  const text = getTitleElement(card['story-elements']);
  return text ? text.text : '';
}

export function getDuration(length) {
  const seconds = Math.floor(length / 1000);
  return `${Math.floor(seconds / 60)}m ${seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}s`;
}
export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + 'h ' : '';
  var mDisplay = m > 0 ? m + 'm ' : '';
  var sDisplay = s > 0 ? s + 's ' : '';
  return hDisplay + mDisplay + sDisplay;
}

export class LocalStorage {
  set(key, value) {
    global.localStorage.setItem(key, JSON.stringify(value));
  }

  get(key) {
    return JSON.parse(global.localStorage.getItem(key));
  }

  remove(key) {
    global.localStorage.removeItem(key);
  }
}

export function formatPrice(price, currency, type, minimumFractionDigits) {
  return (
    price &&
    currency &&
    price?.toLocaleString(type, {
      style: 'currency',
      currency,
      minimumFractionDigits,
    })
  );
}

export function isPrivateMode() {
  return new Promise((resolve) => {
    const on = () => resolve(true);
    const off = () => resolve(false);
    if (window.webkitRequestFileSystem) {
      window.webkitRequestFileSystem(window.TEMPORARY, 1, off, on);
      return;
    }

    if (
      /Firefox/.test(window.navigator.userAgent) ||
      'MozAppearance' in document.documentElement.style
    ) {
      const db = window.indexedDB.open('test');
      db.onerror = on;
      db.onsuccess = off;
      return;
    }

    if (/Edge/.test(window.navigator.userAgent) || window.PointerEvent || window.MSPointerEvent) {
      if (window.indexedDB) {
        return off();
      } else {
        return on();
      }
    }

    if (/Safari/.test(window.navigator.userAgent)) {
      try {
        window.openDatabase(null, null, null, null);
        return off();
      } catch (_) {
        return on();
      }
    }

    return off();
  });
}
export function convertToSlug(text = '') {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}
export function getHeroImage(config, story) {
  const heroImageS3Key = get(story, [
    'alternative',
    'home',
    'default',
    'hero-image',
    'hero-image-s3-key',
  ])
    ? get(story, ['alternative', 'home', 'default', 'hero-image', 'hero-image-s3-key'])
    : get(story, ['hero-image-s3-key'])
      ? get(story, ['hero-image-s3-key'])
      : get(config, ['publisher', 'default_image_s3_key']);
  return heroImageS3Key;
}
export function sectionColor(config, sectionId) {
  const menuItem = get(config, ['layout', 'menu'], []).find(
    (menuItem) => menuItem['item-type'] === 'section' && menuItem['item-id'] === sectionId,
  );
  return get(menuItem, ['data', 'color'], '#2b00f7');
}

export function getStoryHeading(story = {}) {
  return get(story, ['alternative', 'home', 'default', 'headline'])
    ? get(story, ['alternative', 'home', 'default', 'headline'])
    : get(story, 'headline');
}

export function getStockDetails(stocks = {}, index = 0) {
  return get(stocks, [index, 'STOCKID'], null);
}

export function checkStoryType(story) {
  const accessLevel = get(story, ['access-level-value'], null);
  if (accessLevel === 999) {
    return 'paid story';
  } else if (accessLevel === 100) {
    return 'metered story';
  } else {
    return 'free story';
  }
}

export function couponDeepLink(coupon, isReferral) {
  const couponType = isReferral ? 'referral' : 'coupon';
  return `bq-blue-subscription?${couponType}=${coupon}`;
}

export function getStaticConfig(keys) {
  const element = window && window.document && document.getElementById('initial-page');
  if (element) {
    const { config } = JSON.parse(element.innerText) || {};
    return get(config, keys, config);
  }
}

export function sortStoriesByLastPublishedAt(stories = []) {
  return stories.sort(
    (a, b) => parseFloat(b['first-published-at']) - parseFloat(a['first-published-at']),
  );
}

export function sortStoriesByLastUpdatedAt(stories = []) {
  return stories.sort(
    (a, b) => parseFloat(b['last-published-at']) - parseFloat(a['last-published-at']),
  );
}

export function getChildCollectionsUniqueStories(childCollections = [], limit = 20) {
  const items = [];

  for (var collection of childCollections) {
    // eslint-disable-next-line no-unused-vars
    if (get(collection, ['items', 'type']) !== 'collection') {
      const storyLimit =
        collection.slug === 'top-news'
          ? get(collection, 'items', []).slice(0, limit)
          : get(collection, 'items', []).slice(0, limit);
      Array.prototype.push.apply(items, storyLimit);
    }
  }
  let stories = items.filter((item) => {
    if (item.type !== 'collection') {
      return item;
    }
  });

  stories = stories.map(({ story }) => story);

  return stories.length > 0
    ? uniqBy(stories, function (e) {
        return e.id;
      })
    : [];
}

const getPartnersData = () => {
  return wretch('/bqpartners')
    .get()
    .json((response) => response.list);
};

export async function getPartnerfromUtmSource() {
  const partners = await getPartnersData();
  const urlParams = new URLSearchParams(global.location.search);
  const utmSource = urlParams.get('utm_source');
  if (
    !urlParams.has('utm_source') ||
    !partners.find((partner) => String(partner.utm_source) === String(utmSource))
  ) {
    return 'no-partners';
  } else {
    return partners.find((partner) => String(partner.utm_source) === String(utmSource));
  }
}

export const replaceArrayItemByIndex = (arr, index, value) => [
  ...arr.slice(0, index),
  value,
  ...arr.slice(index + 1),
];

export function getDeafultPlanId() {
  const urlParams = new URLSearchParams(global.location.search);
  if (urlParams.has('default_plan')) {
    return parseInt(urlParams.get('default_plan'));
  }
  return 'no_default_plan';
}

export function getDateFromTimestamp(stamp) {
  const t = parseInt(stamp);
  if (!t) {
    return '';
  }
  const dateObject = new Date(t);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const date = dateObject.getDate();

  const stringDate = `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}`;
  return stringDate;
}
export function getTimeFromTimestamp(stamp) {
  const t = parseInt(stamp);
  if (!t) {
    return '';
  }
  const dateObject = new Date(t);
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const stringTime = `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
  return stringTime;
}
export function timeString12hr(timeString) {
  return new Date('1970-01-01T' + timeString + 'Z').toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });
}
export function getNumberOfDaysPassed(stamp) {
  const t = parseInt(stamp);
  if (!t) {
    return '';
  }
  const dateToday = new Date().getTime();
  const diff = (dateToday - stamp) / (1000 * 3600 * 24);
  return parseInt(diff);
}
export function getHoursSinceTimestamp(stamp) {
  const t = parseInt(stamp);
  if (!t) {
    return '';
  }
  const dateToday = new Date().getTime();
  const diff = (dateToday - stamp) / (1000 * 60 * 60);
  return parseInt(diff);
}
export function getExtendedUserInfo(data, field) {
  let value = '';
  for (var i in data.custom_field_values) {
    var fieldName = data.custom_field_values[i].field_name;
    var fieldValue = data.custom_field_values[i].value;
    if (fieldName === field && fieldValue) {
      value = fieldValue;
      break;
    }
  }
  return value;
}

export const getUserDetails = (aid, aesKey) => new Promise((resolve, reject) => {});

export const getWhatsappOptIn = async (phoneNumber) => {
  const respone = await fetch(`/whatsappoptin?phoneNumber=${phoneNumber}`);
  const data = await respone;
};

export const getDeviceType = (size) => {
  let deviceType;
  if (size.width < 480) {
    deviceType = 'mobile';
  } else if (size.width < 1024) {
    deviceType = 'tablet';
  } else if (size.width < 1200) {
    deviceType = 'laptop';
  } else {
    deviceType = 'laptop';
  }
  return deviceType;
};

export const getHeadline = (story, isAlternative = true) => {
  if (!story) {
    return null;
  }

  let headline = null;

  if (isAlternative) {
    headline = get(story, ['alternative', 'home', 'default', 'headline'], null);
  }

  if (!headline) {
    headline = story.headline;
  }

  return headline;
};

export const getReaction = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ['metadata', 'story-attributes', 'reactions', '0'], null);
};

export const getSection = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ['sections', '0', 'name'], null);
};

export const getStorySlug = (story) => {
  if (!story) {
    return null;
  }

  return `/${get(story, ['slug'], null)}`;
};

export const getImage = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ['hero-image-s3-key'], null);
};

export const getAuthor = (story) => {
  if (!story) {
    return null;
  }

  const authorId = get(story, ['author-id'], null);
  if (!authorId) {
    return null;
  }

  const authorList = story.authors || [];
  if (!authorList.length) {
    return null;
  }

  const authorObj = authorList.find((c) => c.id === authorId);
  if (!authorObj) {
    return null;
  }

  return {
    ...authorObj,
    authorSlug: `/author/${authorObj.id}/${authorObj.slug}`,
  };
};

export const isBqBlueExclusiveStory = (story) => {
  if (!story) {
    return false;
  }

  return (story.tags || []).some((c) => c.name === 'bqblue');
};

export const getSubHeadline = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ['subheadline'], null);
};

export const getSummary = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ['summary'], null);
};

export const calculateExpiryDate = (subscriptionEndDate) =>
  subscriptionEndDate && differenceInDays(new Date(subscriptionEndDate), new Date());

export const getTodayDate = (type) => formatInTimeZone(new Date(), 'Asia/Kolkata', type);

export const formatDate = (date, type) => {
  if (!date) {
    return null;
  }

  try {
    return formatInTimeZone(new Date(date), 'Asia/Kolkata', type);
  } catch (error) {}

  return null;
};

export const getNowUnixTime = () => getTime(new Date());

export const formatPublishedDate = (date) =>
  formatDate(date, DATE_FORMATS.HOUR_MINUTE_MERIDIEM) +
  ' IST, ' +
  formatDate(date, DATE_FORMATS.DATE_MONTH_YEAR);

export const getBqExclusiveStories = (items) => {
  const bqStories = [];
  const nonBqStories = [];

  const stories = items.filter((c) => c.type === 'story');

  stories.forEach((element) => {
    isBqBlueExclusiveStory(element.story) ? bqStories.push(element) : nonBqStories.push(element);
  });

  const list = [];
  let i = 0;

  while (bqStories.length !== 0 && nonBqStories.length !== 0) {
    if (i % 2 === 0) {
      list.push(bqStories.shift());
    } else {
      list.push(nonBqStories.shift());
    }

    i++;
  }

  if (list.length < 10) {
    while (bqStories.length !== 0 && list.length < 10) {
      list.push(bqStories.shift());
    }

    while (nonBqStories.length !== 0 && list.length < 10) {
      list.push(nonBqStories.shift());
    }
  }

  return list.slice(0, 10);
};

export const gethhmm = (timestamp) => {
  return new Date(timestamp).toLocaleTimeString('en-Us', {
    timeZone: 'Asia/Kolkata',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const getddmmyyyy = (timestamp) => {
  return formatInTimeZone(timestamp, 'Asia/Kolkata', 'dd MMM yyyy, hh:mm a').slice(0, 11);
};

export const getDDMMYYYY = (timestamp) => {
  if (!timestamp) return null;

  try {
    return formatInTimeZone(timestamp, 'Asia/Kolkata', 'dd-MM-yyyy');
  } catch (error) {
    console.error('Error formatting timestamp:', error);
    return null;
  }
};


export const getStoryDate = (date) => {
  if (!date) {
    return null;
  }
  return `${getddmmyyyy(date)}, ${gethhmm(date) + ' IST'}`;
};

export const countParagraphs = (cards) => {
  return cards.reduce((total, card) => {
    const paragraphs = card['story-elements']?.reduce((count, el) => {
      return count + (el.text?.match(/<p>/g) || []).length;
    }, 0);
    return total + (paragraphs || 0);
  }, 0);
};

export const calculateArticleAge = (publishedAt) => {
  if (!publishedAt) return 'NA';
  const diffInDays = Math.floor((new Date() - new Date(publishedAt)) / (1000 * 60 * 60 * 24));
  return diffInDays >= 0 ? diffInDays : 'NA';
};

export const getStorySectionByScroll = () => {
  const scrollPercentage = Number(localStorage.getItem('scrollPercentage')) || 0;

  if (scrollPercentage >= 0 && scrollPercentage < 25) {
    return 'top-fold';
  } else if (scrollPercentage >= 25 && scrollPercentage < 50) {
    return 'upper-mid-fold';
  } else if (scrollPercentage >= 50 && scrollPercentage < 75) {
    return 'lower-mid-fold';
  } else if (scrollPercentage >= 75 && scrollPercentage <= 100) {
    return 'bottom-fold';
  } else {
    console.warn('Invalid scroll percentage:', scrollPercentage);
    return '';
  }
};

export function checkCookie(tag) {
  var isExist = getCookie(tag);
  if (isExist) {
    return isExist;
  } else {
    return false;
  }
}

export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name) {
  if (typeof window !== 'undefined') {
    var cookieArr = document.cookie.split(';');
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split('=');
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
  }
  return null;
}

export function generateString(length) {
  return (
    'publisherprovidedids' +
    Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)) +
    'ndtvprofitids'
  );
}

export const getCollectionTitle = (collection) => {
  if (!collection) {
    return null;
  }

  return get(collection, ['metadata', 'display-name'], get(collection, ['name'], null));
};

export const textTruncation = (text, maxCharacters) => {
  if (!text) {
    return text;
  }
  return text.length > maxCharacters ? text.slice(0, maxCharacters) + '...' : text;                                                                                                                                                                                                                                           
};

export const sendDataToApp = async (url, widgetName) => {
  try {
    const result = await KM.send('echo', { data: { url: url, widgetName: widgetName } });
  } catch (error) {
    console.log(error);
  }
};

export const deepSearchAndReplace = (target, name, value) => {
  if (typeof target === 'object') {
    for (let key in target) {
      if (typeof target[key] === 'object') {
        deepSearchAndReplace(target[key], name, value);
      } else if (typeof target[key] === 'array') {
        target[key].map((item) =>
          typeof target[item] === 'object'
            ? deepSearchAndReplace(target[item], name, value)
            : (target[item] = value),
        );
      } else {
        if (key === name) {
          target[key] = value;
        }
      }
    }
  }
  return target;
};

export const setSSOUserDetails = async (
  authCookies,
  dispatch,
  accesstypeJwtSecret,
  metypeJwtSecret,
) => {
  const response = await makeApiCall(
    `/sso/user-details?accessToken=${authCookies?.accessToken}&refreshToken=${authCookies?.refreshToken}`,
    'GET',
    {},
    {},
    { cache: 'no-store' },
  );

  if (response.status) {
    const user_info = response.user_info;
    dispatch({ type: USER_PROFILE, payload: user_info });
    dispatch({ type: USER_LOGGEDIN, payload: true });
    const atToken = setTokens(user_info, accesstypeJwtSecret, metypeJwtSecret);
    const subscriber = await checkForSubscription(dispatch, atToken);
    if (subscriber !== null) {
      loginSignupSuccess(user_info, 'section-page', subscriber);
    }
    return subscriber;
  } else if (!response.status && response?.statusCode !== 200) {
    const showCustomToast = async () => {
      const toastId = toast.custom((t) => (
        <div className="session-timeout">
          <img src="/icons/limit.svg" alt="Icon" className="limit-image" />
          <b>Device Limit Reached.</b>&nbsp;You’re Logged Out
        </div>
      ));
      await clearSSOUserDetails(dispatch);
      setTimeout(() => {
        toast.dismiss(toastId);
        window.location.reload();
      }, 5000);
    };
    showCustomToast();
  }
};

export const clearSSOUserDetails = async (dispatch) => {
  dispatch({ type: USER_PROFILE, payload: null });
  dispatch({ type: USER_LOGGEDIN, payload: null });
  localStorage.removeItem('USER_SELECTED_PLAN');
  document.cookie = 'sso_refresh_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = 'sso_access_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = 'hideOneTap=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const getbase64url = (source) => {
  let encodedSource = CryptoJS.enc.Base64.stringify(source);
  encodedSource = encodedSource.replace(/=+$/, '');
  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');

  return encodedSource;
};

export const verifySSODetails = ({
  authCode,
  accessToken,
  client_id,
  client_secret,
  environment,
  dispatch,
  accesstypeJwtSecret,
  metypeJwtSecret,
}) => {
  if (!!authCode) {
    let currentHost = window.location.origin;
    if (environment === 'beta' && window.location.origin.indexOf('localhost') > -1) {
      currentHost = 'http://localhost:3000';
    }

    makeApiCall(
      `/sso/auth?authorizationCode=${authCode}&client_id=${client_id}&client_secret=${client_secret}&redirectUrl=${currentHost}/`,
    ).then((response) => {
      if (response.status) {
        const date = new Date();
        date.setTime(date.getTime() + 180 * 24 * 60 * 60 * 1000);
        document.cookie = `sso_access_token=${
          response.accessToken
        }; expires=${date.toUTCString()}; path=/`;
        date.setTime(date.getTime() + 180 * 24 * 60 * 60 * 1000);
        document.cookie = `sso_refresh_token=${
          response.refreshToken
        }; ; expires=${date.toUTCString()}; path=/`;
        window.history.replaceState(null, '', window.location.pathname);
        setSSOUserDetails(
          {
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
          },
          dispatch,
          accesstypeJwtSecret,
          metypeJwtSecret,
        );
      }
    });
  } else if (!!accessToken && accessToken.length > 0) {
    const authCookies = {
      accessToken: document.cookie
        .split(';')
        .find((c) => c.indexOf('sso_access_token') > -1)
        .trim()
        .replace('sso_access_token=', ''),
      refreshToken: document.cookie
        .split(';')
        .find((c) => c.indexOf('sso_refresh_token') > -1)
        .trim()
        .replace('sso_refresh_token=', ''),
    };
    const queryParams = window?.location?.search;
    window?.history?.replaceState(null, null, queryParams);
    setSSOUserDetails(authCookies, dispatch, accesstypeJwtSecret, metypeJwtSecret);
  } else {
    dispatch({ type: USER_LOGGEDIN, payload: false });
    dispatch({ type: USER_SUBSCRIBER, payload: false });
  }
};

export const makeApiCall = async (
  url,
  method = 'GET',
  body = {},
  headers = {},
  otherOptions = {},
) => {
  try {
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      ...otherOptions,
    };
    if (!isEmpty(body)) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(url, options);
    return await response.json();
  } catch (error) {
    console.error('error = ' + error);
    return;
  }
};

export const formatNumbers = (number, addSign) => {
  const formattedNumber = number.toLocaleString(undefined, { maximumFractionDigits: 3 });
  const firstPart = formattedNumber.split('.')[0];
  const secondPart = !!formattedNumber.split('.')[1]
    ? (formattedNumber.split('.')[1] + '0').slice(0, 2)
    : '00';
  return (
    (addSign && +firstPart >= 0 && firstPart !== '-0' ? '+' + firstPart : firstPart) +
    '.' +
    secondPart
  );
};

export const getFormatedTime = (value) => {
  const date = new Date(Number(value));
  const now = new Date();
  const dayEnd = new Date(new Date().setHours(24, 0, 0, 0));
  const timeDifference = now - date;
  const timeDifferenceInMinutes = Math.floor(timeDifference / (1000 * 60));

  if (timeDifferenceInMinutes < 30) {
    return `${timeDifferenceInMinutes} min(s) ago`;
  } else if (date < dayEnd) {
    return formatDate(value, DATE_FORMATS.HOUR_MINUTE);
  } else {
    return formatDate(value, DATE_FORMATS.DATE_TIME);
  }
};

export const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k';
  } else {
    return num;
  }
};

export const trimNDTVText = (text) => {
  const trimText = 'by ndtv profit';
  const lowerCaseText = text?.toLowerCase();
  const trimIndex = lowerCaseText?.indexOf(trimText);
  if (trimIndex !== -1) {
    const beforeTrimText = text?.substring(0, trimIndex).trim();
    const afterTrimText = text?.substring(trimIndex + trimText.length).trim();
    return beforeTrimText + ' ' + afterTrimText;
  }
  return text;
};

export const getUserInitials = (userProfile) => {
  let initials = '';
  if (!!userProfile && !!userProfile.fullName) {
    const allParts = userProfile.fullName.split(' ');
    let i = 0;
    while (i < allParts.length) {
      if (!!allParts[i]) {
        initials += allParts[i].charAt(0);
      }
      i++;
    }
  }
  return initials;
};

export const formatPhoneNumber = (phone) => {
  return phone.substring(0, 3) + ' ' + phone.substring(3, phone.length);
};

export const formatIndianAmount = (amount) => {
  if (amount >= 10000000) {
    return (amount / 10000000).toFixed(2) + 'Cr';
  } else if (amount >= 100000) {
    return (amount / 100000).toFixed(2) + 'L';
  } else if (amount >= 1000) {
    return (amount / 1000).toFixed(2) + 'K';
  }
  return amount;
};

export const iposFormatDate = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date)) {
    return 'Invalid Date';
  }
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  return `${formattedDay} ${month} ${year}`;
};

export const checkGeoBlock = (GeoTag, story, config = null) => {
  const staticConfig = config || getStaticConfig();
  const sectionCheck = get(staticConfig, ['integrations', 'geoTag', 'sectionCheck'], false);
  const storyTagsCheck = get(staticConfig, ['integrations', 'geoTag', 'storyTagsCheck'], false);
  const syndicationSourceCheck = get(
    staticConfig,
    ['integrations', 'geoTag', 'syndicationSourceCheck'],
    false,
  );
  const storyTags = storyTagsCheck && get(story, ['tags'])?.map((item) => item.name);
  const sourceTags = get(staticConfig, ['integrations', 'geoTag', 'storyTags']);
  const isGeoBlockTagExist =
    storyTagsCheck && storyTags && storyTags?.some((val) => sourceTags?.includes(val));

  if (isGeoBlockTagExist) {
    const isGeoBlock = GeoTag && GeoTag !== 'undefined' && GeoTag === 'IN';
    return isGeoBlock;
  } else {
    const syndicatesource = syndicationSourceCheck && syndicatedSource(story);
    const sectionSlug = sectionCheck && get(story, ['sections'])?.map((item) => item.slug);
    const sources = get(
      staticConfig,
      ['integrations', 'geoTag', 'sources'],
      // ['Bloomberg', 'businessweek', 'bloomberg'],
    );
    const sourceCheck =
      (syndicationSourceCheck && sources.includes(syndicatesource)) ||
      (sectionCheck && sectionSlug.some((val) => sources.includes(val)));
    const isGeoBlock = GeoTag && GeoTag !== 'undefined' && GeoTag !== 'IN' && sourceCheck;
    return isGeoBlock;
  }
};
