import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import './member-menu.module.css';
import { getSSOLoginUrl, getSSORedirectUrl } from '../../../helpers/subscription-helper';
import { get } from 'lodash';
import { signInClick } from '../../../helpers/datalayer-utils';

const LoggedOutView = ({ integrations }) => {
  const [redirectUrl, setRedirectUrl] = useState('');
  const [loginUrl, setLoginUrl] = useState('');
  useEffect(() => {
    const url = new URL(window?.location?.href);
    getSSORedirectUrl(url).then((pageUrl) => {
      setRedirectUrl(pageUrl);
    });
  }, []);
  useEffect(() => {
    getSSOLoginUrl(
      integrations?.sso?.client_id,
      integrations?.sso?.client_secret,
      redirectUrl,
      SSO_LOGIN_URL
    ).then((pageUrl) => {
      setLoginUrl(pageUrl);
    });
  }, [redirectUrl]);
  const SSO_LOGIN_URL = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'subscription', 'login_url'], null)
  );
  const userData = useSelector((state) => get(state, ['userData']));
  const pageType = useSelector((state) => get(state, ['qt', 'pageType']));
  return (
    <React.Fragment>
      <div onClick={() => signInClick(userData, pageType, 'Sign In', 'header')}>
        <button styleName="sign-in-btn" id="user-icon">
          <a href={loginUrl} target="_self">
            <span>Sign In</span>
          </a>
        </button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  integrations: state.qt.config['integrations'],
});

export default connect(mapStateToProps)(LoggedOutView);
