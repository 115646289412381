import React from 'react';
import { SOCIAL_ICONS, STRINGS } from '../../../../constants';
import '../new-footer.module.css';
import get from 'lodash/get';
import { connect, useSelector } from 'react-redux';
import { followIconsDl } from '../../../../helpers/datalayer-utils';

const SocialShare = ({ pageType, sectionName }) => {
  const userData = useSelector((state) => get(state, ['userData']));
  return (
    <div styleName="list">
      <h5 styleName="heading">{STRINGS.FOLLOW_US}</h5>

      <nav styleName="social-icons">
        {SOCIAL_ICONS &&
          SOCIAL_ICONS.filter((icon) => icon.name !== 'NewsLetter').map((item, index) => (
            <a
              key={index}
              styleName="icon-container"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
              aria-label="social-icons"
              onClick={() => followIconsDl(item.name, pageType, userData, sectionName)}
            >
              <i styleName="icon" className={item.icon_code} />
            </a>
          ))}
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pageType: get(state, ['qt', 'pageType'], ''),
});

export default connect(mapStateToProps)(SocialShare);
