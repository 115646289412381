import {
  CLICK_KEY_HIGHLIGHT,
  REMOVE_CLICK_KEY_HIGHLIGHT,
  HIDE_METER_BANNER,
  BQ_PAYWALLMETER_ENABLED,
  BQ_HARDPAYWALL_ENABLED,
  BQ_METER_LIMIT,
  BQ_STORY_PAGE_LOADED_STATUS,
  BQ_USER_STATUS,
  BQ_SEND_DMP_STATUS,
  BQ_CURRENT_SECTION_NAME,
  BQ_SUBSCRIPTION_DETAILS,
  BQ_INCOGNITO_STATUS,
  BQ_STOCK_ALREADY_ADDED,
  BQ_NOTIFICATION_LIST,
  BQ_ENTITY_WATCHLIST_ADD_FROM_STORY,
  BQ_ENTITY_WATCHLIST_ADD,
  BQ_STOCK_SOFT_REMOVE,
  BQ_STOCK_WATCHLIST_UNDO,
  BQ_STOCK_WATCHLIST_GET,
  BQ_STOCK_ALERTS_REMOVE,
  BQ_STOCK_ALERTS_UPDATE,
  BQ_STOCK_ALERTS_MEDIUM_UPDATE,
  BQ_STOCK_ALERTS_ERROR,
  BQ_STOCK_NOTIFICATIONS_DISABLE,
  BQ_STOCK_ALERT_SET,
  SHOW_LOADER,
  REMOVE_LOADER,
  BQ_STOCK_NOTIFICATIONS_SET,
  BQ_WATCHLISTTAB,
  BQ_LIVE_TV_PLAYER_VALUE,
  BOTTOM_STORY_DATA,
  USER_SUBSCRIBER,
  PUSHDOWN_AD,
  USER_LOGGEDIN,
  USER_PROFILE,
  USER_SUBSCRIPTION,
  USER_SELECTED_PLAN,
  USER_SELECTED_BOOKMARKS,
  NP_REGISTRATIONPAYWALL_ENABLED
} from './actions';

const keyHightlightReducer = (state = '', action) => {
  switch (action.type) {
    case CLICK_KEY_HIGHLIGHT:
      return action.cardId;
    case REMOVE_CLICK_KEY_HIGHLIGHT:
      return '';
    default:
      return '';
  }
};

const showMeterBannerReducer = (state = true, action) => {
  if (action.type === HIDE_METER_BANNER) return false;
  return state;
};

const showLoaderReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return true;
    case REMOVE_LOADER:
      return false;
    default:
      return false;
  }
};

const userDetails = {
  sendDmp: false,
  hardpaywall: 'no',
  softpaywall: 'no',
  meterpaywall: 'no',
  meterLimit: 0,
  loginStatus: 'no',
  currentSectionName: null,
  inIncognitoMode: 'no',
  stockWatchList: {},
  stockLimitExceeded: false,
  failure: false,
  notificationList: [],
};

const userDetailsReducer = (state = userDetails, action) => {
  switch (action.type) {
    case BQ_PAYWALLMETER_ENABLED:
      return { ...state, meterpaywall: 'yes' };
    case BQ_HARDPAYWALL_ENABLED:
      return { ...state, hardpaywall: 'yes' };
    case NP_REGISTRATIONPAYWALL_ENABLED:
      return { ...state, softpaywall: 'yes' };
    case BQ_METER_LIMIT:
      return { ...state, meterLimit: action.data };
    case 'QT-MEMBER_UPDATED':
      if (action.member !== null) {
        return { ...state, loginStatus: 'yes' };
      }
      return state;
    case BQ_SEND_DMP_STATUS:
      return { ...state, sendDmp: true };
    case BQ_CURRENT_SECTION_NAME:
      return { ...state, currentSectionName: action.value };
    case BQ_SUBSCRIPTION_DETAILS:
      return { ...state, ...action.data };
    case BQ_INCOGNITO_STATUS:
      return {
        ...state,
        inIncognitoMode: 'yes',
      };
    case BQ_STOCK_ALREADY_ADDED:
      return {
        ...state,
        stockNotification: action.payload.message,
        stockStatus: 'alreadyAdded',
      };
    case BQ_ENTITY_WATCHLIST_ADD_FROM_STORY: {
      const entityStory = action.payload;
      const addedEntityStory = {
        stocks: Object.assign(
          {},
          state.stockWatchList.stocks,
          entityStory.type === 'stock'
            ? { [entityStory.STOCKID]: entityStory }
            : { [entityStory.id]: entityStory }
        ),
      };

      return {
        ...state,
        stockNotification: [
          `${entityStory.name} added to Watchlist &nbsp;<a target="_blank" rel="noopener noreferrer"href = "/my-account/my-watchlist" styleName="subscribe_link"><b> VIEW</b></a>`,
        ],
        stockStatus: 'Add',
        stockWatchList: { ...state.stockWatchList, ...addedEntityStory },
      };
    }
    case BQ_ENTITY_WATCHLIST_ADD: {
      const entity = action.payload;
      const addedEntity = {
        stocks: Object.assign(
          {},
          state.stockWatchList.stocks,
          entity.type === 'stock' ? { [entity.STOCKID]: entity } : { [entity.id]: entity }
        ),
      };
      return {
        ...state,
        stockNotification: [`${entity.name} has been added to your watchlist`],
        stockStatus: 'Add',
        stockWatchList: { ...state.stockWatchList, ...addedEntity },
      };
    }
    case BQ_STOCK_SOFT_REMOVE: {
      const stocktoBeRemoved = action.payload.stocksList;
      const newStockList = { stocks: {} };
      const removedStocksList = { stocks: {} };
      /* eslint-disable */
      for (const stock in state.stockWatchList.stocks) {
        const stockToString = stock.toString();
        if (!stocktoBeRemoved.includes(stockToString)) {
          newStockList.stocks[stockToString] = state.stockWatchList.stocks[stockToString];
        } else {
          removedStocksList.stocks[stockToString] = state.stockWatchList.stocks[stockToString];
        }
      }
      /* eslint-enable */

      const removedStocks =
        stocktoBeRemoved.length > 1
          ? `${stocktoBeRemoved.length} entities have`
          : `${
              state.stockWatchList.stocks[stocktoBeRemoved[0]].COMPNAME ||
              state.stockWatchList.stocks[stocktoBeRemoved[0]].name
            } has`;

      return {
        ...state,
        stocktoBeUndo: { ...removedStocksList },
        stockNotification: [`${removedStocks} been removed from your watchlist`],
        stockStatus: 'Remove',
        stockWatchList: { ...state.stockWatchList, ...newStockList },
      };
    }
    case BQ_STOCK_WATCHLIST_UNDO: {
      const undoStockList = {
        stocks: { ...state.stockWatchList.stocks, ...state.stocktoBeUndo.stocks },
      };
      return {
        ...state,
        stockWatchList: { ...state.stockWatchList, ...undoStockList },
      };
    }
    case BQ_STOCK_WATCHLIST_GET:
      return { ...state, stockWatchList: action.payload };
    case BQ_STOCK_ALERTS_REMOVE: {
      const alerts = state.stockWatchList.alerts.filter(
        (time) => time !== action.payload.alert.time
      );
      return {
        ...state,
        stockWatchList: { ...state.stockWatchList, alerts: alerts },
        stockStatus: 'Remove',
        stockNotification: [
          `Your Alert ${action.payload.alert.id + 1} for ${action.payload.alert.time} is removed`,
        ],
      };
    }
    case BQ_STOCK_ALERTS_UPDATE:
      return {
        ...state,
        stockWatchList: {
          ...state.stockWatchList,
          alerts: action.payload.alerts.map((a) => a.time),
        },
        stockNotification: action.payload.message,
        stockStatus: 'setAlarm',
      };
    case BQ_STOCK_ALERTS_ERROR:
      return { ...state, stockNotification: action.payload.message, failure: true };
    case BQ_STOCK_ALERTS_MEDIUM_UPDATE: {
      const { alertMediums } = action.payload;
      const selections = [];
      Object.keys(alertMediums).map((mode) => alertMediums[mode] && selections.push(mode));

      return {
        ...state,
        stockWatchList: {
          ...state.stockWatchList,
          alertMediums: selections,
        },
      };
    }
    case BQ_NOTIFICATION_LIST: {
      const newNotificationList = [...state.notificationList, action.payload.notification];
      return { ...state, notificationList: [...newNotificationList] };
    }
    case BQ_STOCK_NOTIFICATIONS_DISABLE:
      clearTimeout(state.notificationList[0]);
      state.notificationList.shift();
      if (state.notificationList.length === 0) {
        return { ...state, stockNotification: null };
      } else {
        return { ...state, notificationList: [...state.notificationList] };
      }
    case BQ_STOCK_NOTIFICATIONS_SET:
      return {
        ...state,
        stockNotification: [action.payload.message],
        stockStatus: 'LimitExceed',
        stockLimitExceeded: true,
      };
    case BQ_STOCK_ALERT_SET: {
      const stocksToBeUpdated = Object.keys(action.payload);
      let setMessage = '';
      let removeMessage = '';
      const stocks = stocksToBeUpdated.reduce((acc, stock) => {
        acc[stock] = state.stockWatchList.stocks[stock];
        acc[stock].alert = action.payload[stock].alert;

        const entityName =
          state.stockWatchList.stocks[stock].name || state.stockWatchList.stocks[stock].COMPNAME;

        if (acc[stock].alert) {
          if (setMessage.length > 0) {
            setMessage = setMessage + ',';
          }
          setMessage = setMessage + entityName;
        } else {
          if (removeMessage.length > 0) {
            removeMessage = removeMessage + ',';
          }
          removeMessage = removeMessage + entityName;
        }
        return acc;
      }, state.stockWatchList.stocks);
      const messages = [];
      if (setMessage.length > 0) {
        messages.push(`${setMessage} has been added to your alert list`);
      }
      if (removeMessage.length > 0) {
        messages.push(`${removeMessage} has been removed from your alert list`);
      }
      const netState = {
        ...state,
        stockNotification: messages,
        stockStatus: 'setAlert',
        stockWatchList: { stocks },
      };
      return netState;
    }
    case BQ_WATCHLISTTAB:
      return { ...state, isWatchlistTab: action.payload };
    default:
      return state;
  }
};

const loadedModules = {
  storyPageLoaded: false,
  userStatus: false,
  liveTvEnabled: false,
};

const userDataReducer = (
  state = {
    isSubscriber: null,
    isLoggedIn: null,
    userProfile: null,
    userSubscription: null,
    userSelectedPlan: null,
    userBookmarks: null
  },
  action
) => {
  switch (action.type) {
    case USER_SUBSCRIBER: {
      return {
        ...state,
        isSubscriber: action.payload,
      };
    }
    case USER_LOGGEDIN: {
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    }
    case USER_PROFILE: {
      return {
        ...state,
        userProfile: action.payload,
      };
    }
    case USER_SUBSCRIPTION: {
      return {
        ...state,
        userSubscription: action.payload,
      };
    }
    case USER_SELECTED_PLAN: {
      return {
        ...state,
        userSelectedPlan: action.payload,
      };
    }
    case USER_SELECTED_BOOKMARKS: {
      return {
        ...state,
        userBookmarks: action.payload,
      };
    }
    default:
      return state;
  }
};

const pushDownAdVisible = (state = false, action) => {
  switch (action.type) {
    case PUSHDOWN_AD: {
      return (state = action.payload);
    }
    default:
      return state;
  }
};

const loadedModulesReducer = (state = loadedModules, action) => {
  switch (action.type) {
    case BQ_USER_STATUS:
      return {
        ...state,
        userStatus: true,
      };
    case BQ_STORY_PAGE_LOADED_STATUS:
      return {
        ...state,
        storyPageLoaded: true,
      };
    case BQ_LIVE_TV_PLAYER_VALUE:
      return {
        ...state,
        liveTvEnabled: action.payload,
      };
    default:
      return state;
  }
};

const bottomStoryDataReducer = (state = {}, action) => {
  switch (action.type) {
    case BOTTOM_STORY_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default {
  pageLoading: showLoaderReducer,
  cardHighlighted: keyHightlightReducer,
  meterShown: showMeterBannerReducer,
  userDetails: userDetailsReducer,
  loadedModules: loadedModulesReducer,
  bottomStoryData: bottomStoryDataReducer,
  userData: userDataReducer,
  pushDownAdVisible: pushDownAdVisible,
};
